.importPopup {
  background: #fff;
  border-radius: 2px;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #012965;
  width: 100vw;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
}

.carting_head {
  display: flex;
  padding: 12px;
  height: 75px;
  width: 100%;
  position: relative;
}

.pageTitleSection {
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pageLogoHolder {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  overflow: hidden;
  float: left;
  margin-right: 15px;
}

.pageLogo {
  width: 100%;
  height: 100%;
}

.pageTitle h2,
.pageTitle {
  float: left;
  font-size: 18px;
  font-family: var(--robotoC);
  margin: 0 !important;
  font-family: var(--robotoC);
  font-weight: 300;
}

.itemTable {
  width: 100%;
}
.closeCart {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: rgba(255, 0, 0, 0.2);
  border-radius: 2px;
  font-family: var(--robotoC);
  width: 27px;
  height: 27px;
  cursor: pointer;
  top: 25px;
  right: 42px;
  font-size: 40px;
  text-decoration: none;
  background-color: #fff;
}

.import_container {
  width: calc(100% - 24px);
  height: calc(100% - 167px);
  display: flex;
  padding: 12px 100px;
  box-sizing: border-box;
  /* border-top: 1px solid rgba(1, 41, 101, 0.4); */
  margin: 0 12px;
}

.item_container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.filterSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
}

.nav {
  width: 100%;
  padding: 0 12px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: space-between;
}

.nav_item_left {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.option_sub_group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.navItem {
  /* padding-left: 6px; */
  display: flex;
  /* margin-left: 6px; */
}

.navItem:first-child {
  margin-left: 0;
}

.filterSection .nav {
  width: 100%;
}
.searchbar {
  width: 160px;
}
.searchbox {
  border: 1px solid rgb(1 41 101 / 30%);
  height: 24px;
  padding: 0 8px;
  width: calc(100% - 25px);
  font-weight: 300;
}
.searchbox:focus-visible {
  outline: none;
}

.searchIcon {
  width: 25px;
  height: 24px;
  border: 1px solid rgb(1 41 101 / 30%);
  background-color: #fff;
}

.filterSelect_item {
  height: 26px;
  width: 160px;
  border: 1px solid rgb(1 41 101 / 30%);
  color: #012965;
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 14px;
  background-color: #fff;
}

.btn_rotate_unset {
  transition: all 200ms ease;
  transform: rotate(0deg);
}

.btn_rotate {
  transition: all 200ms ease;
  transform: rotate(90deg);
}

.db_item {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid rgb(1 41 101 / 20%);
}

.table_header {
  background-color: #d9dfe8;
  position: sticky;
  top: 0;
  /* z-index: 1; */
}

.sl_section {
  width: 80px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  color: #012965;
  font-size: 14px;
  font-family: var(--robotoC);
}

.check_section {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  color: #012965;
  font-size: 22px;
  cursor: pointer;
}

.img_section {
  width: 80px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  padding: 5px;
}

.img_section img {
  max-width: 100%;
  max-height: 100%;
}

.title_section {
  margin: 0;
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 14px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: calc(100% - 400px);
}

.exist_section {
  margin: 0;
  width: 150px;
}

.exist_label {
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 2px;
  background-color: #d9dfe8;
}

.pageHeaderSection {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 100px 12px;
}

/* .pageHeaderSection:first-child {
    justify-content: flex-start;
} */

.paginationSection {
  display: flex;
  align-items: center;
  height: 28px;
}

.per_page {
  font-size: 13px;
  opacity: 60%;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.btn_page {
  color: #012965;
  font-size: 0.8rem;
  padding: 0px 10px;
  height: 26px;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
  background-color: #fff;
  border: 1px solid rgb(1 41 101 / 30%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2px;
}

.btn_page:focus-visible {
  outline: none;
}

.wrap_popup {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background-color: #0129652e;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning_popup {
  width: 400px;
  background-color: #fff;
  border-radius: 2px;
  height: auto;
}

.popup_head {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
}

.pop_title {
  font-size: 18px;
  font-weight: 500;
  color: #012965;
  font-family: var(--robotoC);
}

.closePopup {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: rgba(255, 0, 0, 0.2);
  border-radius: 2px;
  font-family: var(--robotoC);
  width: 27px;
  height: 27px;
  cursor: pointer;
  top: 10px;
  right: 15px;
  font-size: 28px;
  text-decoration: none;
  background-color: #fff;
}

.popup_body {
  width: 100%;
  padding: 6px 45px;
}

.pop_check_section {
  display: flex;
  align-items: center;
  padding: 0 0;
  cursor: pointer;
}

.check_label {
  font-size: 16px;
  font-family: var(--robotoC);
  color: rgb(1, 41, 101);
  margin-left: 6px;
  user-select: none;
  font-weight: 300;
}

.pop_body_text {
  font-size: 16px;
  color: #012965;
  font-family: var(--robotoC);
}

.popup_foot {
  height: 45px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pop_cancel {
  padding: 0 16px;
  border: 1px solid #012965;
  border-radius: 2px;
  background-color: #fff;
  color: #012965;
  font-family: var(--robotoC);
  margin-right: 10px;
}

.pop_submit {
  padding: 0 16px;
  border: 1px solid #012965;
  border-radius: 2px;
  background-color: #012965;
  color: #fff;
  font-family: var(--robotoC);
  margin-right: 10px;
}
