.dispatch_container {
    height: 100%;
}
.main_head {
    position: relative;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.main_head h3 {
    margin: 0;
    font-weight: 400;
}

.top_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: -40px;
    right: 0;
    width: 100%;
    padding: 0 15px;
}

.titleWithBC {
    display: flex;
    align-items: center;
    height: 100%;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    margin: 0;
    padding-left: 8px;
}

.breadcrumb_item {
    font-weight: 100;
    padding-right: 8px;
    display: list-item;
    text-align: -webkit-match-parent;
}

.breadcrumb_item+.breadcrumb_item::before {
    display: inline-block;
    padding-right: 8px;
    color: #012965;
    content: "|";
}

.breadcrumb_item:last-child {
    opacity: 80%;
}

.main_body {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 15px 0 0;
    justify-content: space-between;
    height: calc(100% - 40px);
}

.mapContainer {
    width: 60%;
    height: 100%;
    padding-right:15px;
}

.assigneeContainer {
    width: 40%;
    height: 100%;
    padding-left:15px;
}

.mapBody {
    display: flex;
    align-items: center;
    justify-content: center;
}
.assigneHead {
    width: 100%;
}
.assigneTabCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.assigneeTab {
    width: 33.33%;
    text-align: center;
    color: #012965;
    font-weight: 300;
    position: relative;
    cursor: pointer;
}
.assigneeTab.active::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 6px;
    border-radius: 0 0 10px 10px;
    background-color: #012965;
    left: 0;
    top: -16px;
}

.assigneSearch {
    width: 100%;
    margin-top: 15px;
}

.assigneSearchInput {
    width: 100%;
    height: 24px;
    border: 1px solid rgba(1, 41, 101, 0.3);
    border-radius: 15px;
    padding: 10px;
    font-size: 14px;
    color: #012965;
    font-weight: 300;
}
.assigneSearchInput:focus-visible {
    outline: none;
}

.assigneBody {
    width: 100%;
    height: calc(100% - 90px);
    overflow-y: auto;
}
.assigneItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid rgb(1 41 101 / 20%);
}
.itemDetailsSec {
    width: 65%;
}
.itemDetailsSec p {
    font-size: 15px;
    font-weight: 300;
    margin: 0;
}
.itemBtnSec {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.itemBtnSec p {
    font-size: 15px;
    font-weight: 300;
    margin: 0;
}
.assignBtn {
    border: none;
    border-radius: 15px;
    color: #FFF;
    background-color: #012965;
    font-size: 14px;
    font-weight: 300;
    padding: 3px 15px;
    margin-top: 18px;
}