.wrapper{
    width: 100vw !important;
    height: 100%;
    position: fixed;
    background-color: rgb(1 41 101 / 35%);
    z-index: 2;
    right: 0;
    top:0;
}
.storeSettingPopup {
  width: 450px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1 ;
  background-color: #fff;
  flex-direction: column;
  box-shadow: 0px 2px 6px 5px rgb(0 0 0 / 12%), 0px 2px 4px -1px rgb(0 0 0 / 7%);

}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.itemDesIcon {
  cursor: pointer;
}

.addSub_header {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  border-bottom: 1px solid rgb(1 41 101 / 20%);
  margin: 0 !important;
}

/*
              color: "#00365a",
              fontSize: 16,
              borderColor: "#00365a",
              fontSize: 16,
              backgroundColor: "#00365a",
              borderColor: "#00365a",   
*/

.formContainer {
  width: 100%;
  height: calc(100% - 120px);
  overflow-y: auto;
  padding: 25px;
  border-bottom: 1px solid rgb(1 41 101 / 20%);
}
.submitButtons{
  display: flex;
  align-items: center;
  height: 60px
}

.addSub_header p {
  margin: 0;
}

.addSub_header span {
  width: 16px;
  height: 16px;
}

.addSub_body {
  height: calc(100% - 120px);
  width: 100%;
  padding: 40px 5px;
  overflow-y: auto;
}

.addSub_footer {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  border-top: 1px solid rgb(1 41 101 / 20%);
}

.imgButton {
  position: relative;
  top: 5px;
  left: 320px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: solid 1px #01296579;
  transition: 100ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.imgButton:hover {
  background-color: #012965;
  color: #fff;
}

.imgButton label i {
  position: relative;
  top: 7.5px;
}

.customFileInput {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.imageAddBtn {
  height: 38px;
  border: 1px solid #012965;
  background-color: #012965;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.featureItemAddBtn {
  height: 38px;
  border: 1px solid #012965;
  background-color: #012965;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.cancelBtn {
  border: 1px solid rgb(1 41 101 / 20%);
  background-color: transparent;
  color: #012965;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: var(--robotoC);
  height: 26px;
  width: 120px;
  margin-right: 15px;
  
}

.cancelBtn:hover {
  background-color: #012965;
  color: #fff;
}

.submitBtn {
  border: none;
  background-color: #012965;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: var(--robotoC);
  height: 26px;
  width: 120px;
}
.submitBtn:hover{
   background-color: #00193f;
  color: #fff;
  transition: 150ms ease-in-out;
}
.selectBox{
  width: 210px;
  height: 26px;
  border: 1px solid rgb(1 41 101 / 20%);
  font-size: 14px;
}
.selectBox:focus{
  border-radius: 0;
}
.textFront{
border: 1px solid rgb(1 41 101 / 20%);
padding: 6px 10px;
background-color: #00193f10;
}
.selectBoxLg{
  width: 400px;
  height: 28px;
  border: 1px solid rgb(1 41 101 / 20%);
  font-size: 14px;
} 
.selectBoxSm{
  width: 133px;
  height: 28px;
  border: 1px solid rgb(1 41 101 / 20%);
  font-size: 14px;
}
.TimePicker{
   border: 1px solid rgb(1 41 101 / 20%);
}