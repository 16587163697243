.searchSuggestionPopup {
    background: #fff;
    border-radius: 2px;
    font-family: var(--robotoC);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #012965;
    width: 100vw;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
}

.carting_head {
    display: flex;
    padding: 12px;
    height: 85px;
    width: 100%;
    position: relative;
}

.pageTitleSection {
    width: auto;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.pageLogoHolder {
    width: 40px;
    height: 40px;
    border-radius: 2px;
    overflow: hidden;
    float: left;
    margin-right: 15px;
}

.pageLogo {
    width: 100%;
    height: 100%;
}

.pageTitle h2,
.pageTitle {
    float: left;
    font-size: 18px;
    font-family: var(--robotoC);
    margin: 0 !important;
    font-family: var(--robotoC);
    font-weight: 300;
}

.closeCart {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    color: rgba(255, 0, 0, 0.2);
    border-radius: 2px;
    font-family: var(--robotoC);
    width: 27px;
    height: 27px;
    cursor: pointer;
    top: 25px;
    right: 42px;
    font-size: 40px;
    text-decoration: none;
    background-color: #fff;
}

.searchInputSection {
    height: 38px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(1, 41, 101, 0.1);
    position: relative;
    display: flex;
    margin: 0 100px;
}

.searchInputSection input {
    background: #fff;
    border: none;
    border-radius: 2px;
    color: #012965;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    height: 100%;
    letter-spacing: .6px;
    padding: 2px 45px 2px 38px;
    width: 100%;
}

.searchInputSection input:focus-visible {
    outline: none;
}

.searchInputSection .searchButton {
    align-items: center;
    border-radius: 0 2px 2px 0;
    color: #012965;
    cursor: pointer;
    display: flex;
    font-family: var(--robotoC);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    height: 100%;
    justify-content: center;
    letter-spacing: .02em;
    line-height: 17px;
    position: absolute;
    width: 24px;
}

.container {
    width: 100%;
    padding: 0 100px;
    height: calc(100% - 123px);
}

.suggest_container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.closeSearch {
    position: absolute;
    right: 32px;
    top: 30px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    cursor: pointer;
}

.srItemHolder {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.itemInCart .sriDetails {
    background-color: rgb(1 41 101 / 5%);
}

.srItemAdd {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

.srItem {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 2px;
    text-decoration: none;
}

.sriDetails {
    padding: 0 10px 0 6px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.srItem:hover .sriDetails {
    background-color: rgb(1 41 101 / 10%);
}

.sri_image {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sri_image img {
    max-width: 100%;
    max-height: 100%;
}

.sri_name {
    width: calc(100% - 90px);
    padding-left: 5px;
}

.sri_name p {
    font-family: var(--robotoC);
    font-weight: 300;
    font-size: 15px;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #012965;
    text-transform: capitalize;
}

.sri_price {
    width: 90px;
    font-family: var(--robotoC);
    font-weight: 300;
    font-size: 15px;
    text-align: right;
    color: #012965;
}

.notFound {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--robotoC);
    font-weight: 300;
    font-size: 16px;
    color: #012965;
}

._btn_dec {

}
._btn_inc {

}
._btn_add {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    border: 1px solid rgb(255 0 0 / 20%);
    background-color: #fff;
    color: #ff0000;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alreadyIncart {
    background-color: #ff0000;
    color: #fff;
}

.cart_total_section {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 2px;
    width: 190px;
    height: 27px;
    cursor: pointer;
    top: 25px;
    right: 99px;
    text-decoration: none;
    background-color: #fff;
}
.pro_qty {
    font-size: 15px;
    font-family: var(--robotoC);
    font-weight: 300;
    margin-left: 2px;
    color: #ff0000;
}

.cartTotal {
    color: #ff0000;
    font-weight: 300;
    font-family: var(--robotoC);
    font-size: 15px;
    margin-left: 8px;
}

.fractionValue {
    font-size: 12px;
}