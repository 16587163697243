.wrapper {
    position: fixed;
    width: 100vw;
    height: 100%;
    /* background-color: rgba(81, 94, 123, 0.5); */
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    z-index: 9;
}
.deleteContainer {
    width: 600px;
    padding: 60px 80px;
    background-color: #fff;
    border: 1px solid rgb(255 0 0 / 40%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iconSec {
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon {
    color: #ff0000;
    font-size: 55px;
}
.titleSec {
    display: flex;
    align-items: center;
    justify-content: center;
}
.titleSec h2{
    font-family: var(--robotoC);
    font-size: 26px;
    color: #012965;
    font-weight: 500;
    margin-bottom: 12px;
}
.subTitleSec {
    display: flex;
    align-items: center;
    justify-content: center;
}
.subTitleSec p{
    font-family: var(--robotoC);
    font-size: 18px;
    color: #012965;
    font-weight: 300;
    text-align: center;
    margin-bottom: 28px;
    line-height: 24px;
}
.buttonSec {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    column-gap: 15px;
}

.button {
    border: 1px solid #012965;
    padding: 0 8px;
    background-color: transparent;
    color: #012965;
    border-radius: 2px;
    height: 24px;
    min-width: 120px;
    font-family: var(--robotoC);
}
.delete {
    background-color: #ff0000;
    color: #fff;
    border: none;
}