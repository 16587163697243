.settings_page {
    height: 100%;
    width: 100%;
    position: relative;
}

.itemsPageHeader {
    width: 100%;
    height: 45px;
    background-color: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pageHeaderSection {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pageHeaderSection:first-child {
    justify-content: flex-start;
}

.titleWithBC {
    display: flex;
    align-items: center;
    height: 100%;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    margin: 0;
    padding-left: 8px;
}

.breadcrumb_item {
    font-weight: 100;
    padding-right: 8px;
    display: list-item;
    text-align: -webkit-match-parent;
}

.breadcrumb_item+.breadcrumb_item::before {
    display: inline-block;
    padding-right: 8px;
    color: #012965;
    content: "|";
}

.breadcrumb_item:last-child {
    opacity: 80%;
}


.main_body {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding-top: 10px;
    height: calc(100% - 45px);
}

.settings_cotainer {
    height: 100%;
    overflow-y: auto;
}

.settingBtn {
    height: 26px;
    padding: 0 15px;
    color: #012965;
    border: 1px solid rgb(1 41 101 / 30%);
    /* border-radius: 2px; */
    background-color: #ffffff;
    font-family: var(--robotoC);
    font-size: 14px;
    line-height: 14px;
  }
  .settingBtn:hover {
    background-color: #012965;
    color: #ffffff;
  }