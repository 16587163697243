.dashboard {
  width: 100%;
  height: 100vh;
  display: flex;
  background: url("../British-Flag-1.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.right_section {
  -webkit-backdrop-filter: blur(31.6501px);
  backdrop-filter: blur(31.6501px);
  background: hsla(0, 0%, 100%, 0.95);
  float: left;
  height: 100%;
  transition: 0.3s ease;
  width: calc(100% - 250px);
  padding: 3px 42px;
}
.body_section {
  width: calc(100%);
  height: calc(100% - 40px);
}

.main_container {
  font-family: var(--robotoC);
  color: #012965;
  height: calc(100%);
  width: 100%;
  padding: 15px 0;
}
.main_head {
  position: relative;
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.main_head h3 {
  margin: 0;
  font-weight: 400;
}
.top_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: -40px;
  right: 0;
  width: 100%;
  padding: 0 15px;
}
.titleWithBC {
  display: flex;
  align-items: center;
  height: 100%;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  margin: 0;
  padding-left: 8px;
}
.breadcrumb::before {
  display: inline-block;
  padding-right: 8px;
  color: #012965;
  content: "|";
}
.breadcrumb_item {
  font-weight: 100;
  padding-right: 8px;
  display: list-item;
  text-align: -webkit-match-parent;
}
.breadcrumb_item + .breadcrumb_item::before {
  display: inline-block;
  padding-right: 8px;
  color: #012965;
  content: "|";
}
.breadcrumb_item:last-child {
  opacity: 80%;
}

.settingBtn {
  font-family: var(--robotoC);
  font-size: 12px;
  color: #012965;
  background-color: #ffffff;
  border: 1px solid rgba(1, 41, 101, 0.5);
  border-radius: 15px;
  padding: 0 8px;
}
.settingBtn:hover {
  background-color: #012965;
  color: #ffffff;
}
.export_btn {
  color: #012965;
  border: none;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  box-shadow: 0px 0px 2px rgb(80, 80, 80) !important;
  transition: 300ms ease-in-out;
  height: 24px;
  padding: 3px 8px;
}
.export_btn:hover {
  background-color: #012965;
  color: #ffffff;
  /* border: solid 1px #012965; */
}
.tabButton {
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 5px 0;
  width: 100% !important;
  text-decoration: none;
  color: #002050;

  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  cursor: pointer;
  background-clip: padding-box;
  appearance: none;
  box-shadow: 0px 0px 2px rgb(129, 129, 129);
}
.tabButton.active,
.tabButton:hover {
  color: #ffffff;
  background-color: #00337a;
}

.tab_item {
  text-align: center;
  height: 85px;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  border-radius: 2px;
  text-decoration: none;
  color: #012965;
}
.tab_item i {
  width: 100%;
  font-size: 25px;
}
.tab_item p {
  font-size: 14px;
  font-weight: 300;
}

.filterSection {
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(1, 41, 101, 0.3);
}
.filterSelect {
  margin-right: 15px;
  height: 24px;
}
.filterSelect select {
  width: 160px;
  height: 24px;
  border-radius: 2px;
  border: 1px solid rgba(1, 41, 101, 0.3);
  color: #012965;
  font-weight: 300;
}
.filterSelect select:focus-visible {
  outline: none;
}

.filterSearch {
  height: 24px;
}
.filterSearch input {
  width: 180px;
  height: 24px;
  border-radius: 2px;
  border: 1px solid rgba(1, 41, 101, 0.3);
  padding: 0 10px;
  color: #012965;
  font-weight: 300;
}
.filterSearch input:focus-visible {
  outline: none;
}

.nav {
  width: 100%;
  margin-bottom: 8px;
  padding-top: 0 !important;
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1px;
  list-style: none;
  border-radius: 0.25rem;
  margin-top: 0;
  justify-content: space-between;
}
.nav_item_left {
  display: flex;
  flex-wrap: wrap;
}
.navItem {
  padding-right: 6px;
  margin-bottom: 5px;
}
.searchBy {
  height: 26px;
  color: #012965;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem 0.2rem 0.7rem;
  transition: background-color box-shadow 0.1s linear;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
}
.searchBy:hover {
  background-color: #012965;
  color: #ffffff;
  border: solid 1px #012965;
}
.searchbar {
  height: 26px;
  overflow: hidden;
  font-size: 0.76rem;
  line-height: 1.3;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  transition: background-color box-shadow 0.1s linear;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
}
.searchbox {
  border: none;
  background: none;
  float: left;
  outline: none;
}
.searchIcon {
  padding: 0;
  float: right;
  font-family: "simple-line-icons";
  font-size: 14px;
  color: #012965;
  border: none;
  background: none;
  /* border-top-right-radius: 25px; */
  /* border-bottom-right-radius: 25px; */
  text-align: center;
  cursor: pointer;
}
.btn_delivery {
  color: #012965;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
  height: 26px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem 0.2rem 0.7rem;
  transition: background-color box-shadow 0.1s linear;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
}
.btn_delivery span i {
  padding-left: 10px;
}
.searchBy span i {
  padding-left: 10px;
}
.btn_delivery:hover {
  background-color: #012965;
  color: #ffffff;
  border: solid 1px #012965;
}
.btn_payment {
  color: #012965;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
  height: 26px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem 0.2rem 0.7rem;
  transition: background-color box-shadow 0.1s linear;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
}
.btn_payment span i {
  padding-left: 10px;
}
.btn_payment:hover {
  background-color: #012965;
  color: #ffffff;
  border: solid 1px #012965;
}
.btn_apply {
  color: #ffffff;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
  height: 26px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.2rem 1rem 0.2rem 1rem;
  transition: background-color box-shadow 0.1s linear;
  border: solid 1px #012965;
  background-color: #012965;
  transition: 250ms ease-in-out;
}
.btn_apply:hover {
  background-color: #001d47;
}
.btn_page {
  color: #012965;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  margin-top: 10px;
  padding: 0.2rem 1rem 0.2rem 1rem;
  transition: background-color box-shadow 0.1s linear;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
  margin-right: -6px;
}
.btn_page span i {
  padding-left: 10px;
}
.per_page {
  padding-right: 8px;
  font-size: 13px;
  opacity: 60%;
}
.bottomSeparator {
  margin-top: 20px;
  margin-bottom: 00px;
  width: 100% !important;
  border-bottom: solid 1px #cacaca;
}
.separator {
  margin-top: 0px;
  margin-bottom: 00px;
  width: 100% !important;
  border-bottom: solid 1px #cfcfcf;
}
.main_body {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 0 15px;
  justify-content: center;
}
.table_heads {
  background-color: #ececec;
}
.table_heads th {
  font-size: 16px;
  font-weight: 400;
}
.thead_light {
  color: #001d47;
}
.tbody tr td {
  font-size: 14px;
  font-weight: 400;
}
.page_link {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #aaaaaa;
  border-radius: 40px;
  color: #001d47;
  text-decoration: none;
  height: 24px;
  width: 24px;
  margin: 8px;
  list-style: none;
}

.domain_popup {
  align-items: center;
  background-color: hsla(0,0%,100%,.95);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.domain_popup_inner {
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(255,0,0,.4);
  display: flex;
  flex-direction: column;
  padding: 30px 80px;
  width: 450px;
  min-height: 300px;
}

.domain_pop_title {
  color: #012965;
  font-family: Poppins;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.domains_container {
  width: 100%;
  min-height: 200px;
  overflow-y: auto;
}
.domain_item {
  line-height: 35px;
  cursor: pointer;
  border-bottom: 1px solid rgb(1 41 101 / 20%);
  padding: 0 10px;
}
.domain_item.active {
  background-color: rgba(255,0,0,.1);
}

.ok_btn {
  background-color: #fff;
  border: 1px solid rgba(255,0,0,.3);
  border-radius: 2px;
  color: red;
  font-size: 15px;
  height: 26px;
  min-width: 100px;
  padding: 0 16px;
}