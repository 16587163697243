.order_container {
  width: 100%;
  height: 100%;
}

.itemsPageHeader {
  width: 100%;
  height: 45px;
  background-color: transparent;
  padding: 0x;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageHeaderSection {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pageHeaderSection:first-child {
  justify-content: flex-start;
}

.titleWithBC {
  display: flex;
  align-items: center;
  height: 100%;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  margin: 0;
  padding-left: 8px;
}

.breadcrumb::before {
  display: inline-block;
  padding-right: 8px;
  color: #012965;
  content: "|";
}

.breadcrumb_item {
  font-weight: 100;
  padding-right: 8px;
  display: list-item;
  text-align: -webkit-match-parent;
}

.breadcrumb_item + .breadcrumb_item::before {
  display: inline-block;
  padding-right: 8px;
  color: #012965;
  content: "|";
}

.breadcrumb_item:last-child {
  opacity: 80%;
}

.exportButton {
  height: 24px;
  padding: 0 15px;
  color: #012965;
  border: 1px solid rgb(1 41 101 / 30%);
  background-color: #ffffff;
  font-family: var(--robotoC);
  font-size: 14px;
  transition: 100ms ease-in-out;
}

.exportButton:hover {
  background-color: #012965;
  color: #ffffff;
}

.addItemBtn {
  float: right;
  border: none;
  box-shadow: 0px 0px 2px rgb(80, 80, 80) !important;
  color: #012965;
  padding: 4px 15px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  float: right;
  transition: 300ms ease-in-out;
}

.addItemBtn:hover {
  background-color: #001d47;
  color: #fff;
}

.tabButton {
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 5px 0;
  width: 100% !important;
  text-decoration: none;
  color: #002050;

  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  cursor: pointer;
  background-clip: padding-box;
  appearance: none;
  box-shadow: 0px 0px 2px rgb(129, 129, 129);
}

.tabButton.active,
.tabButton:hover {
  color: #ffffff;
  background-color: #00337a;
}

.tab_item {
  text-align: center;
  height: 85px;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  border-radius: 2px;
  text-decoration: none;
  color: #012965;
}

.tab_item i {
  width: 100%;
  font-size: 25px;
}

.tab_item p {
  font-size: 14px;
  font-weight: 300;
}
.filterSelect_item {
  height: 26px;
  width: 170px;
  border: 1px solid rgb(1 41 101 / 30%);
  color: #012965;
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 14px;
}
.filterSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.filterSection .nav {
  width: 100%;
}

.filterSelect {
  margin-right: 15px;
  height: 24px;
}

.filterSelect select {
  width: 160px;
  height: 24px;
  border-radius: 2px;
  border: 1px solid rgba(1, 41, 101, 0.3);
  color: #012965;
  font-weight: 300;
}

.filterSelect select:focus-visible {
  outline: none;
}

.filterSearch {
  height: 24px;
}

.filterSearch input {
  width: 180px;
  height: 24px;
  border-radius: 2px;
  border: 1px solid rgba(1, 41, 101, 0.3);
  padding: 0 10px;
  color: #012965;
  font-weight: 300;
}

.filterSearch input:focus-visible {
  outline: none;
}

.nav {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: space-between;
}

.nav_item_left {
  display: flex;
  flex-wrap: wrap;
}

.nav_item_right {
  display: flex;
  flex-wrap: wrap;
}

.navItem {
  padding-left: 6px;
}
.navItem:first-child {
  padding-left: 0;
}

.searchBy {
  height: 26px;
  width: 150px;
  border: 1px solid rgb(1 41 101 / 30%);
  color: #012965;
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 14px;
}

/* .searchBy:hover {
  background-color: #012965;
  color: #ffffff;
  border: solid 1px #012965;
} */

.searchbar {
  display: flex;
  align-items: center;
  height: 26px;
  overflow: hidden;
  font-size: 12px;
  padding: 0 8px;
  transition: 250ms ease-in-out;
  border: 1px solid rgb(1 41 101 / 30%);
}

.searchbox {
  border: none;
  background: none;
  float: left;
  outline: none;
}

.searchIcon {
  padding: 0;
  float: right;
  font-family: "simple-line-icons";
  font-size: 14px;
  color: #012965;
  border: none;
  background: none;
  /* border-top-right-radius: 25px; */
  /* border-bottom-right-radius: 25px; */
  text-align: center;
  cursor: pointer;
}

.btn_delivery {
  color: #012965;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
  height: 26px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem 0.2rem 0.7rem;
  transition: background-color box-shadow 0.1s linear;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
}

.btn_delivery span i {
  padding-left: 10px;
}

.searchBy span i {
  padding-left: 10px;
}

.btn_delivery:hover {
  background-color: #012965;
  color: #ffffff;
  border: solid 1px #012965;
}

.btn_payment {
  color: #012965;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
  height: 26px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem 0.2rem 0.7rem;
  transition: background-color box-shadow 0.1s linear;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
}

.btn_payment span i {
  padding-left: 10px;
}

.btn_payment:hover {
  background-color: #012965;
  color: #ffffff;
  border: solid 1px #012965;
}

.btn_apply {
  height: 26px;
  padding: 0 15px;
  background-color: #012965;
  border: 1px solid #01296569;
  color: #ffffff;
  font-family: var(--robotoC);
  font-size: 0.8rem;
  transition: 100ms ease-in-out;
  position: relative;
  top: -1px;
}

.btn_apply:hover {
  background-color: #001d47;
}

.btn_page {
  color: #012965;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  margin-top: 10px;
  padding: 0.2rem 1rem 0.2rem 1rem;
  transition: background-color box-shadow 0.1s linear;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
  margin-right: -6px;
}

.btn_page span i {
  padding-left: 10px;
}

.per_page {
  padding-right: 8px;
  font-size: 13px;
  opacity: 60%;
}

.bottomSeparator {
  margin-top: 20px;
  margin-bottom: 00px;
  width: 100% !important;
  border-bottom: solid 1px #cacaca;
}

.separator {
  margin-top: 0px;
  margin-bottom: 00px;
  width: 100% !important;
  border-bottom: solid 1px #cfcfcf;
}

.main_body {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 15px 0 0;
  justify-content: center;
  height: calc(100% - 72px);
}
.orderContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.subStore_card {
  float: left;
  height: 300px;
  width: calc(33.33% - 10px);
  margin-right: 15px;
  margin-bottom: 15px;
}

.subStore_card:nth-child(3n) {
  margin-right: 0;
}

.card_body {
  position: relative;
}
.approve_text {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #07bc0c;
  color: #fff;
  font-size: 8px;
  font-family: "Poppins";
  letter-spacing: 0.8px;
  padding: 0 5px;
  border-radius: 0 0 0 5px;
  line-height: 12px;
}

.text_label {
  font-size: 14px;
}

.table_heads {
  background-color: #ececec;
}

.table_heads th {
  font-size: 16px;
  font-weight: 400;
}

.thead_light {
  color: #001d47;
}

.tbody tr td {
  font-size: 14px;
  font-weight: 400;
}

.page_link {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #aaaaaa;
  border-radius: 40px;
  color: #001d47;
  text-decoration: none;
  height: 24px;
  width: 24px;
  margin: 8px;
  list-style: none;
}

.ItemEditBtn {
  height: 24px;
  padding: 0 15px;
  color: #012965;
  border: 1px solid #01296543;
  background-color: #ffffff;
  font-family: var(--robotoC);
  font-size: 14px;
  /* line-height: 14px; */
  transition: 100ms ease-in-out;
  display: flex;
  align-items: center;
  float: right;
}
.ItemEditBtn:hover {
  background-color: #012965;
  color: #ffffff;
}
.paginationSection {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.resetFilters {
  height: 100%;
  color: #fff;
  line-height: 1.3;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem 0.2rem 0.7rem;
  /* border: solid 1px #012965; */
  transition: 250ms ease-in-out;
  background-color: #012965;
  border: 1px solid rgb(1 41 101 / 30%);
}
