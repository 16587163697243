.right_bar_button {
  float: right;
  position: absolute;
  z-index: 100;
  top: 0px;
  right: 0px;
}
.right_bar_button button {
  position: relative;
  top: 40px;
  width: 250px;
  height: 24px;
  color: #ffffff;
  cursor: pointer;
  background: #012965;
  box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 12%),
    0px 2px 4px -1px rgb(0 0 0 / 7%);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  z-index: 100;
}

.right_bar_button button span i {
  color: #ffffff;
}
.icon_span {
  position: relative;
  right: 10px;
  transition: 0.25s ease-in-out;
}
.active_icon_span {
  position: relative;
  right: 10px;
  transform: rotate(180deg);
  transition: 0.25s ease-in-out;
}
.right_bar {
  transition: 200ms ease-in-out;
  background: rgba(230, 233, 237, 0.97);
  float: right;
  height: 100%;
  transition: 0.5s ease;
  width: 250px;
  position: relative;
  top: 15px;
  right: 0;
}
.aside_head {
  height: 50px;
  box-sizing: border-box;
  width: 100%;
  padding: 15px 15px 10px;
  cursor: pointer;
  display: flow-root;
}
.aside_container {
  width: 100%;
  height: calc(100vh - 105px);
  padding: 0px 15px;
}
.aside_foot {
  height: 30px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aside_foot p {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #012965;
}
.UKEUbox {
  width: 100%;
  height: 24px;
  color: #ffffff;
  cursor: pointer;
  background: #012965;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
}
.UKEUbrand {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
}
.category_list {
  width: 100%;
  height: calc(100vh - 130px);
  float: left;
  overflow-y: auto;
  position: relative;
  border-top: 1px solid rgb(1 41 101 / 20%);
  border-bottom: 1px solid rgb(1 41 101 / 20%);
}
.group_items {
  float: left;
  width: 100%;
  height: auto;
  padding-bottom: 10px;
}
.group_items:first-child {
  margin-top: 25px;
}

.group_items:last-child {
  margin-bottom: 25px;
  padding-bottom: 0px;
}
.main_category_container {
  height: 24px;
  background: #f5f5f6;
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  padding: 0px 4px 0px 10px;
  cursor: pointer;
}
.main_category_container .icon_p {
  align-items: center;
  color: #012965;
  display: flex;
  float: right;
  font-size: 13px;
  height: 24px;
  justify-content: center;
  transition: all 0.2s ease;
  width: 25px;
}
.title {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #012965;
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
  float: left;
  user-select: none;
  text-decoration: none;
}
.title:hover {
  text-decoration: underline;
}
.icon_p.active {
  transform: rotate(180deg);
}
.sub_category_container {
  width: 100%;
  height: auto;
  float: left;
  user-select: none;
  padding-left: 0;
  padding-top: 8px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0 !important;
}
.sub_category_container .subCate_item {
  flex-basis: 33.33%;
  height: 85px;
  width: 33.33%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  float: left;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  border-radius: 2px;
  text-decoration: none;
  color: #012965;
}
.sub_category_container .subCate_item:hover::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(1 41 101 / 6%);
  border-radius: 2px;
  pointer-events: none;
}
.sub_category_container .subCate_item.active {
  border: 1px solid rgb(1 41 101 / 50%);
}

.sub_category_container div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  float: left;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 2px;
  padding-top: 2px;
}

.sub_category_container .subCate_item img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  object-fit: cover;
  text-align: center;
  margin-bottom: 5px;
}

.sub_category_container .subCate_item p {
  width: 92%;
  height: 35px;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #012965;
  text-align: center;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.sub_category_container .subCate_item i {
  text-align: center;
  font-size: 22px;
  border-radius: 100%;
  height: 30px;
  object-fit: cover;
  width: 30px;
}
