.brand_page {
    height: 100%;
    width: 100%;
    position: relative;
}

.itemsContainer {
    width: 100%;
    height: 100%;
    padding: 10px 0;
}

.titleWithBC {
    display: flex;
    align-items: center;
    height: 100%;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    margin: 0;
    padding-left: 15px;
}

.breadcrumb_item {
    font-weight: 100;
    padding-right: 8px;
    display: list-item;
    text-align: -webkit-match-parent;
}

.breadcrumb_item+.breadcrumb_item::before {
    display: inline-block;
    padding-right: 8px;
    color: #012965;
    content: "|";
}

.breadcrumb_item:last-child {
    opacity: 80%;
}

.mainItems {
    width: 100%;
    padding: 15px;
    /* border-radius: 4px; */
    background-color: #fff;
    border-bottom: 1px solid rgb(1 41 101 / 20%);
    /* box-shadow: 0px 2px 8px #d8d8d8, -6px -6px 12px #ffffff; */
    /* margin-bottom: 15px; */
}

.itemSection {
    display: flex;
    align-items: center;
    height: 60px;
    cursor: pointer;
}

.itemImage {
    width: 100px;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemTitle {
    width: calc(100% - 200px);
    height: 100%;
    display: flex;
    align-items: center;
}

.itemTitle p {
    margin: 0;
}

.itemPrice {
    width: 120px;
    float: right;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemPrice p {
    margin: 0;
    text-align: center;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
}

.custom-switch {
    padding-left: 2.25rem;
}

.itemsPageHeader {
    width: 100%;
    height: 45px;
    background-color: transparent;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pageHeaderSection {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pageHeaderSection:first-child {
    justify-content: flex-start;
}

.addItemBtn {
    border: none;
    background-color: #012965;
    color: #fff;
    padding: 4px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.addItemBtn:hover {
    color: #fff;
}

.searchSection {
    margin-right: 15px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchInput {
    border-radius: 2px;
    border: 1px solid rgba(1, 41, 101, 0.5);
    font-size: 14px;
    color: #012965;
    padding: 3px 10px;
}

.searchInput:focus-within {
    outline: none;
}

.nav {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: space-between;
}

.nav_item_left {
    display: flex;
    flex-wrap: wrap;
}

.navItem {
    padding-left: 6px;
}

.searchBy {
    height: 100%;
    color: #012965;
    cursor: pointer;
    padding: 0.25rem 0.75rem;
    font-size: 0.76rem;
    line-height: 1.3;
    outline: initial !important;
    box-shadow: none !important;
    box-shadow: initial !important;
    font-size: 0.8rem;
    padding: 0.2rem 0.7rem 0.2rem 0.7rem;
    transition: background-color box-shadow 0.1s linear;
    border: solid 1px #012965;
    border-radius: 2px;
    transition: 250ms ease-in-out;
    background-color: #ffffff;
}

.searchBy:hover {
    background-color: #012965;
    color: #ffffff;
    border: solid 1px #012965;
}

.searchbar {
    display: flex;
    align-items:center;
    height: 26px;
    overflow: hidden;
    font-size: 12px;
    padding: 0px 8px;
    border: solid 1px #012965;
    transition: 250ms ease-in-out;
    border: 1px solid rgb(1 41 101 / 30%);
}

.searchbox {
    border: none;
    background: none;
    float: left;
    outline: none;
}

.searchIcon {
    padding: 0;
    float: right;
    font-family: "simple-line-icons";
    font-size: 14px;
    color: #012965;
    border: none;
    background: none;
    text-align: center;
    cursor: pointer;
}

.searchBy span i {
    padding-left: 10px;
}


.main_body {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding-top: 10px;
    height: calc(100% - 45px);
    display: flex;
}

.page_divider {
    width: 50%;
    height: 100%;
}

.page_divider:first-child {
    /* border-right: 1px solid rgb(1 41 101 / 20%); */
    padding-right: 4.5%;
}
.page_divider:last-child {
    padding-left: 4.5%;
}

.listContainer {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.brandItemImg {
    max-width: 100%;
    height: 100%;
    padding: 6px;
    object-fit: cover;
}

.brandItemTitle {
    margin: 0;
    padding-left: 10px;
    font-family: var(--robotoC);
    font-size: 14px;
    color: #012965;
}

.brandItem {
    height: 50px;
    border-bottom: 1px solid rgb(1 41 101 / 10%);
    display: flex;
    align-items: center;
    padding: 0 10px;
    position: relative;
    cursor: pointer;
}

.brandItem:hover {
    background-color: rgb(1 41 101 / 3%);
}

.brandItemHolder {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.EditBtn {
    height: 24px;
    font-family: var(--robotoC);
    font-size: 14px;
    color: #012965;
    border-radius: 2px;
    border: 1px solid rgb(1 41 101 / 30%);
    background-color: #ffffff;
    position: absolute;
    right: 11px;
    visibility: hidden;
}

.EditBtn:hover {
    background-color: #012965;
    color: #ffffff;
}
.brandItem:hover  .EditBtn {
    visibility: visible;
}

.editAction {
    color: rgb(1 41 101);
    padding: 0 8px;
    border-right: 1px solid rgb(1 41 101 / 30%);
}
.delteAction {
    color: rgb(1 41 101);
    padding: 0 8px;
}
.EditBtn:hover .editAction {
    color: rgb(255 255 255);
    border-right: 1px solid rgb(255 255 255 / 30%);
}
.EditBtn:hover .delteAction {
    color: rgb(255 255 255);
}