.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 9;
}

.commentPopup {
    width: 250px;
    height: 200px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 25%), 0px 4px 28px rgb(180 180 180 / 25%);
}

.commentHead {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 5px 10px;
}

.commentTitle {
    color: #012965;
}

.commentClose {
    position: absolute;
    right: 13px;
    font-size: 18px;
    cursor: pointer;
}

.commentBody {
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.commentBox {
    width: 100%;
    height: 100%;
    border: 1px solid rgb(1 41 101 / 20%);
    border-radius: 2px;
    resize: none;
    padding: 5px;
}

.commentBox:focus-visible {
    outline: none;
}

.commentFoot {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 10px;
    padding-right: 10px;
}

.commentFoot button {
    height: 24px;
    border: none;
    display: flex;
    background-color: #012965;
    color: #fff;
    align-items: center;
    padding: 2px 15px;
    border-radius: 2px;
    cursor: pointer;
}