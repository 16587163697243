.brand_page {
    height: 100%;
    width: 100%;
    position: relative;
}


.titleWithBC {
    display: flex;
    align-items: center;
    height: 100%;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    margin: 0;
    padding-left: 15px;
}

.breadcrumb_item {
    font-weight: 100;
    padding-right: 8px;
    display: list-item;
    text-align: -webkit-match-parent;
}

.breadcrumb_item+.breadcrumb_item::before {
    display: inline-block;
    padding-right: 8px;
    color: #012965;
    content: "|";
}

.breadcrumb_item:last-child {
    opacity: 80%;
}

.itemsPageHeader {
    width: 100%;
    height: 45px;
    background-color: transparent;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pageHeaderSection {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pageHeaderSection:first-child {
    justify-content: flex-start;
}


.nav {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: space-between;
}

.nav_item_left {
    display: flex;
    flex-wrap: wrap;
}

.navItem {
    padding-left: 6px;
    display: flex;
}
.dateLabel {
    background-color: rgb(1 41 101 / 20%);
    color: #012965;
    padding: 0 5px;
    font-size: 13px;
    font-weight: 300;
    font-family: var(--robotoC);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: -1px;
}

.searchbar {
    height: 24px;
    overflow: hidden;
    font-size: 0.76rem;
    line-height: 1.3;
    outline: initial !important;
    box-shadow: none !important;
    box-shadow: initial !important;
    font-size: 0.8rem;
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
    transition: background-color box-shadow 0.1s linear;
    border: solid 1px #012965;
    border-radius: 2px;
    transition: 250ms ease-in-out;
}

.filterBtn {
    background-color: #012965;
    color: #fff;
}

.filterBtn .searchIcon{
    color: #fff;
    font-family: var(--robotoC);
}

.searchbox {
    border: none;
    background: none;
    float: left;
    outline: none;
}

.searchIcon {
    padding: 0;
    float: right;
    font-family: "simple-line-icons";
    font-size: 14px;
    color: #012965;
    border: none;
    background: none;
    text-align: center;
    cursor: pointer;
}

.searchBy span i {
    padding-left: 10px;
}

.filterSection {
    width: 100%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
}

.filterSection .nav {
    width: auto;
}

.paginationSection {
    display: flex;
    align-items: center;
}


.page_link {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(1 41 101 / 30%);
    border-radius: 40px;
    color: #001d47;
    text-decoration: none;
    font-family: var(--robotoC);
    font-size: 14px;
    height: 24px;
    width: 24px;
    list-style: none;
    margin: 0 !important;
}

.page_link:hover {
    background-color: #012965;
    color: #fff;
}

.btn_page {
    color: #012965;
    font-size: 0.8rem;
    padding: 3px 10px;
    height: 24px;
    border: solid 1px #012965;
    transition: 250ms ease-in-out;
    margin-right: -6px;
    background-color: #fff;
    border: 1px solid rgb(1 41 101 / 30%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn_page span i {
    padding-left: 10px;
}


.per_page {
    font-size: 13px;
    opacity: 60%;
    margin-right: 5px;
    display: flex;
    align-items: center;
}

.main_body {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding-top: 10px;
    height: calc(100% - 45px);
    /* display: flex; */
}

.thead_light {
    color: #001d47;
}

.table_heads {
    background-color: #ececec;
}

.table_heads th {
    font-size: 16px;
    font-weight: 400;
}

.tbody tr td {
    font-size: 14px;
    font-weight: 400;
}

.activity_img {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
}

.activity_img img {
    max-width: 100%;
    max-height: 100%;
}