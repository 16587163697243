.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(1 41 101 / 20%);
    position: fixed;
    z-index: 9;
    right: 0;
    bottom: 0;
}
.cropContainer {
    width: 500px;
    height: 400px;
    padding: 24px;
    background-color: #fff;
    border-radius: 2px;
}
.cropBody {
    width: 100%;
    display: flex;
    height: calc(100% - 50px);
    border: 1px solid rgb(1 41 101 / 20%);
}
.cropFooter {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cropConfirm {
    height: 24px;
    color: #fff;
    background-color: #012965;
    border: none;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:  0 10px;
}

.cropBody img{
    max-width: 100%;
    max-height: 100%;
    transform-origin: center;
}