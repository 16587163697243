.left_bar {
  background: rgba(230, 233, 237, 0.97);
  float: left;
  height: 100%;
  transition: 0.3s ease;
  width: 250px;
}

.aside_head {
  height: auto;
  box-sizing: border-box;
  width: 100%;
  padding: 45px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.aside_container {
  width: 100%;
  height: calc(100vh - 255px);
  padding: 0px 15px;
}

.aside_foot {
  height: 30px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aside_foot p {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #012965;
  margin-bottom: 0;
}

.logo {
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(1 41 101 / 30%);
  font-size: 20px;
  color: #012965;
}

.logo img {
  width: 50px;
  height: 50px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}

.UKEUbox {
  width: 100%;
  height: 24px;
  color: #ffffff;
  cursor: pointer;
  background: #012965;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
}

.UKEUbrand {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.category_list {
  width: 100%;
  height: calc(100% - 24px);
  float: left;
  overflow-y: auto;
  position: relative;
  border-top: 1px solid rgb(1 41 101 / 20%);
  border-bottom: 1px solid rgb(1 41 101 / 20%);
}

.group_items {
  float: left;
  width: 100%;
  height: auto;
  /* padding-bottom: 10px; */
  margin-bottom: 15px;
  background-color: rgb(1 41 101 / 8%);
  border-radius: 2px;
}

.group_items:first-child {
  margin-top: 25px;
}

.group_items:last-child {
  margin-bottom: 25px;
  padding-bottom: 0px;
}

.main_category_container {
  width: 100%;
  height: 24px;
  display: flex;
  background: #f5f5f6;
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  padding: 0px 4px 0px 10px;
  cursor: pointer;
}

.main_category_container .icon_p {
  align-items: center;
  color: #012965;
  display: flex;
  float: right;
  font-size: 13px;
  height: 24px;
  justify-content: center;
  transition: all 0.2s ease;
  width: 25px;
  margin-bottom: 0px !important;
}

.title {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #012965;
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
  float: left;
  user-select: none;
  text-decoration: none;
  margin-bottom: 0px !important;
}

.icon_p.active {
  transform: rotate(180deg);
}

.sub_category_container {
  width: 100%;
  height: auto;
  float: left;
  user-select: none;
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0;
  /* background-color: #ffffff57; */
}

.sub_category_container .subCate_item {
  height: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  border-radius: 2px;
  text-decoration: none;
  color: #012965;
  background-color: #f5f5f6;
  margin-top: 15px;
}

a.subCate_item {
  padding: 0 10px;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: right;
  justify-content: flex-end;
  background-color: transparent !important;
}

a.subCate_item:hover {
  background-color: rgb(1 41 101 / 7%) !important;
  text-decoration: underline;
}

/* .asideFixedContainer .sub_category_container .subCate_item {
  height: 62px;
}
.sub_category_container .subCate_item:hover::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(1 41 101 / 6%);
  border-radius: 2px;
  pointer-events: none;
}
.sub_category_container .subCate_item.active {
  border: 1px solid rgb(1 41 101 / 50%);
} */

.sub_category_container div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  float: left;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 2px;
}

.sub_category_container .subCate_item img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  object-fit: cover;
}

.secondCollapse {
  height: auto !important;
  background-color: transparent !important;
  padding: 0 10px 0px;
}

.sub_category_container:last-child .secondCollapse {
  padding: 0 10px 10px;
}

.secondContainer {
  height: 24px;
  background: #f5f5f6;
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  padding: 0px 4px 0px 10px;
  cursor: pointer;
  margin-top: 0 !important;
  justify-content: space-between !important;
}

/* .sub_category_container .subCate_item p {
  width: 92%;
  height: 35px;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #012965;
  text-align: center;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.asideFixedContainer .sub_category_container .subCate_item p {
  height: 18px;
  margin-bottom: 0px;
}
.sub_category_container .subCate_item i {
  text-align: center;
  font-size: 22px;
  border-radius: 100%;
  height: 30px;
  object-fit: cover;
  width: 30px;
} */

.asideFixedContainer {
  display: flow-root;
  padding: 0 0 45px;
}

.userName {
  width: 100%;
  height: 24px;
  color: #ffffff;
  cursor: pointer;
  background-color: #012965;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin-top: 15px;
  text-align: center;
  position: relative;
}

.logOutBtn {
  position: absolute;
  width: 100%;
  height: 24px;
  top: 24px;
  position: absolute;
  width: 100%;
  height: 30px;
  top: 45px;
  background-color: rgb(1 41 101 / 10%);
  visibility: hidden;
  transition: all 250ms ease;
  z-index: 0;
  color: transparent;
}
.logOutBtn:hover {
  background-color: #012965;
  border-top: 1px solid rgb(255 255 255 / 15%);
}
.userName:hover .logOutBtn{
  visibility: visible;
  top: 24px;
  color: #ffffff;
}

.collapseBody {
  display: flow-root;
  border: 1px solid rgb(1 41 101 / 8%);
  position: relative;
  top: -1px;
  margin-top: 0 !important;
  padding-left: 30px;
}
.collapseBody .collapseBody {
  background-color: #ffffff5e;
  padding-bottom: 15px;
}

.addNewItem {
  width: 100%;
  height: 24px;
  color: rgb(255 0 0 / 80%);
  cursor: pointer;
  background-color: transparent;
  border: 1px solid rgb(255 0 0 / 50%);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  text-align: center;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-decoration: none;
}

.addNewItem:hover {
  color: #ffffff;
  background-color: rgb(255 0 0 / 80%);
}