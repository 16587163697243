@import url("../src/assets/fonts/stylesheet.css");

:root {
  --roboto: "Roboto";
  --robotoC: "Roboto Condensed";
  --poppins: "Poppins"
}

/* Box sizing rules */
* {
  font-family: var(--poppins);
}

.tox .tox-editor-header {
  z-index: unset !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
  border: 0;
  font: inherit;
  padding: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  text-decoration: none;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.MainContent::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border: 1px solid #fafafa;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border: 1px solid #fafafa;
  display: none;
}

.MainContent::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}


.bs_input_f {
  height: 24px;
  padding: 0 10px !important;
  font-size: 14px !important;
  color: #012965 !important;
}

.input-group-text {
  height: 24px;
  padding: 0 !important;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #012965;
}

.bs_btn {
  height: 24px;
  padding: 0 !important;
  width: 30px;
  font-size: 15px !important;
}

.pagination {
  margin: 0;
}

.page-item {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent !important;
}

.pagination-page.page-item.active .active {
  background-color: #012965 !important;
  color: #fff;
}

.css-qbdosj-Input {
  padding: 0 !important;
  height: 24px;
  margin: 0 !important;
}

.css-15lsz6c-indicatorContainer,
.css-1xc3v61-indicatorContainer {
  padding: 0 5px !important;
}

.css-15lsz6c-indicatorContainer {
  color: #012965 !important;
}

.css-t3ipsp-control,
.css-13cymwt-control {
  min-height: 24px !important;
  height: 24px !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.basic-multi-select .css-t3ipsp-control,
.basic-multi-select .css-13cymwt-control {
  height: auto !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0px 8px !important;
  height: 24px !important;
}

.css-1hb7zxy-IndicatorsContainer {
  height: 24px !important;
}

.basic-multi-select .css-1hb7zxy-IndicatorsContainer {
  height: auto !important;
}

.css-19bb58m {
  margin: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.css-1wy0on6 {
  height: 24px !important;
}

.loaderContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 50%);
  /* backdrop-filter: blur(1px); */
  display: flex;
  background-image: url('../src/assets/loader.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px;
  z-index: 9;
  border-radius: 2px;
}

.css-1dimb5e-singleValue {
  color: #012965 !important;
}


.quill {
  min-height: 100%;
  max-height: calc(100%);
  overflow-y: auto;
}

.ql-editor {
  min-height: 274px;
}

.item_editor .ql-editor {
  min-height: 314px;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0 !important;
}

.switch_domain {
  width: 75%;
  padding: 0px 20px;
  border: none;
  background: none;
  font-weight: 600;
}

.nav-link.active {
  border-bottom: 2px solid;
}

.nav-item {
  cursor: pointer;
}


.StripeElement {
  padding: 12px;
}