.brand_page {
    height: 100%;
    width: 100%;
    position: relative;
}

.itemsPageHeader {
    width: 100%;
    height: 45px;
    background-color: transparent;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pageHeaderSection {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pageHeaderSection:first-child {
    justify-content: flex-start;
}

.addItemBtn {
    border: none;
    background-color: #012965;
    color: #fff;
    padding: 4px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.addItemBtn:hover {
    color: #fff;
}


.titleWithBC {
    display: flex;
    align-items: center;
    height: 100%;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    margin: 0;
    padding-left: 15px;
}

.breadcrumb_item {
    font-weight: 100;
    padding-right: 8px;
    display: list-item;
    text-align: -webkit-match-parent;
}

.breadcrumb_item+.breadcrumb_item::before {
    display: inline-block;
    padding-right: 8px;
    color: #012965;
    content: "|";
}

.breadcrumb_item:last-child {
    opacity: 80%;
}


.nav {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: space-between;
}

.nav_item_left {
    display: flex;
    flex-wrap: wrap;
}

.navItem {
    padding-left: 6px;
}

.searchbar {
    display: flex;
    align-items:center;
    height: 26px;
    overflow: hidden;
    font-size: 12px;
    padding: 0px 8px;
    border: solid 1px #012965;
    transition: 250ms ease-in-out;
    border: 1px solid rgb(1 41 101 / 30%);
}

.searchbox {
    border: none;
    background: none;
    float: left;
    outline: none;
}

.searchIcon {
    padding: 0;
    float: right;
    font-family: "simple-line-icons";
    font-size: 14px;
    color: #012965;
    border: none;
    background: none;
    text-align: center;
    cursor: pointer;
}

.searchBy span i {
    padding-left: 10px;
}

.main_body {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding-top: 10px;
    height: calc(100% - 45px);
    display: flex;
}

.page_divider {
    width: 50%;
    height: 100%;
}

.page_divider:first-child {
    /* border-right: 1px solid rgb(1 41 101 / 20%); */
    padding-right: 4.5%;
}

.page_divider:last-child {
    padding-left: 4.5%;
}

.listContainer {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.orderItem {
    height: 50px;
    border-bottom: 1px solid rgb(1 41 101 / 10%);
    display: flex;
    align-items: center;
    padding: 0 10px;
    position: relative;
    cursor: pointer;
}

.orderItem:hover {
    background-color: rgb(1 41 101 / 3%);
}

.orderItemHolder {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.orderItemImg {
    max-width: 100%;
    height: 100%;
    padding: 6px;
    object-fit: cover;
}

.orderItemTitle {
    margin: 0;
    padding-left: 10px;
    font-family: var(--robotoC);
    font-size: 14px;
    color: #012965;
}

.EditBtn {
    height: 24px;
    font-family: var(--robotoC);
    font-size: 14px;
    color: #012965;
    border-radius: 2px;
    border: 1px solid rgb(1 41 101 / 30%);
    background-color: #ffffff;
    position: absolute;
    right: 11px;
    visibility: hidden;
}

.EditBtn:hover {
    background-color: #012965;
    color: #ffffff;
}

.orderItem:hover .EditBtn {
    visibility: visible;
}

.editAction {
    color: rgb(1 41 101);
    padding: 0 8px;
    border-right: 1px solid rgb(1 41 101 / 30%);
}

.delteAction {
    color: rgb(1 41 101);
    padding: 0 8px;
}

.EditBtn:hover .editAction {
    color: rgb(255 255 255);
    border-right: 1px solid rgb(255 255 255 / 30%);
}

.EditBtn:hover .delteAction {
    color: rgb(255 255 255);
}

.alreadyIncart:hover,
.alreadyIncart {
    visibility: visible;
    background-color: #ff0000;
    border-color: #ff0000;
    color: #fff;
}

.alreadyIncart .delteAction {
    color: #fff;
}


.cartSection {
    position: relative;
    /* margin-bottom: 15px; */
    height: 100%;
}

.cart_header {
    display: flex;
    justify-content: space-between;
    /* position: absolute; */
    /* top: -29px; */
    width: 100%;
    height: 35px;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid rgb(1 41 101 / 20%);
}

.cart_items {
    width: 100%;
    height: calc(100% - 35px);
    overflow-y: auto;
}

.cart_item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.cart_itemLeft {
    width: calc(100% - 120px);
}

.cart_itemRight {}

.cart_item_title {
    font-size: 14px;
    line-height: 18px;
}
.cart_item_qty {
    font-size: 12px;
    color: rgb(1 41 101 / 50%);
}
.cart_item_price {
    font-size: 14px;
    text-align: right;
}
.cart_item_remove {
    font-size: 14px;
    color: #ff0000;
    cursor: pointer;
    text-align: right;
}

.cart_total_section {
    display: flex;
    margin-left: 33px;
    justify-content: flex-end;
    align-items: center;
    border-radius: 2px;
    width: auto;
    height: 26px;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
}

.pro_qty {
    font-size: 15px;
    font-family: var(--robotoC);
    font-weight: 300;
    margin-left: 2px;
    color: #ff0000;
}

.cartTotal {
    color: #ff0000;
    font-weight: 300;
    font-family: var(--robotoC);
    font-size: 15px;
    margin-left: 8px;
}

.fractionValue {
    font-size: 12px;
}