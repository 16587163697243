.wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(1 41 101 / 30%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}
.preview_popup {
    width: 70%;
    height: 80%;
    background-color: #fff;
    border-radius: 2px;
    display: block;
    position: relative;
}
.preview_header {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 11px;
}
.closePopup {
    /* width: 30px; */
    /* height: 30px; */
    font-size: 38px;
    color: #ff0000;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 15px;
}
.preview_body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 50px;
}
.preview_body img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 2px;
}