.itemList_page {
  height: 100%;
}

.itemsContainer {
  width: 100%;
  height: calc(100% - 70px);
  overflow-y: auto;
  padding: 10px 0;
  margin-top: 10px;
  border-top: 4px solid rgb(1 41 101 / 60%);
}

.titleWithBC {
  display: flex;
  align-items: center;
  height: 100%;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  margin: 0;
  padding-left: 15px;
}

/* .breadcrumb::before {
  display: inline-block;
  padding-right: 8px;
  color: #012965;
  content: "|";
} */

.breadcrumb_item {
  font-weight: 100;
  padding-right: 8px;
  display: list-item;
  text-align: -webkit-match-parent;
}

.breadcrumb_item+.breadcrumb_item::before {
  display: inline-block;
  padding-right: 8px;
  color: #012965;
  content: "|";
}

.breadcrumb_item:last-child {
  opacity: 80%;
}

.mainItems {
  width: 100%;
  padding: 10px;
  /* border-radius: 4px; */
  background-color: #fff;
  border-bottom: 1px solid rgb(1 41 101 / 20%);
  /* box-shadow: 0px 2px 8px #d8d8d8, -6px -6px 12px #ffffff; */
  /* margin-bottom: 15px; */
}

.itemSection {
  display: flex;
  align-items: center;
  min-height: 60px;
  cursor: pointer;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
}

.custom-switch {
  padding-left: 2.25rem;
}

.itemsPageHeader {
  width: 100%;
  height: 45px;
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageHeaderSection {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pageHeaderSection:first-child {
  justify-content: flex-start;
}

.addItemBtn {
  border: none;
  background-color: #012965;
  color: #fff;
  padding: 4px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.addItemBtn:hover {
  color: #fff;
}

.searchSection {
  margin-right: 15px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchInput {
  border-radius: 2px;
  border: 1px solid rgba(1, 41, 101, 0.5);
  font-size: 14px;
  color: #012965;
  padding: 3px 10px;
}

.searchInput:focus-within {
  outline: none;
}

.nav {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: space-between;
}

.nav_item_left {
  display: flex;
  flex-wrap: wrap;
}

.navItem {
  padding-left: 6px;
  display: flex;
}

.navItem:first-child {
  padding-left: 0;
}

.searchBy {
  height: 100%;
  color: #012965;
  line-height: 1.3;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem 0.2rem 0.7rem;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
  background-color: #fff;
  border: 1px solid rgb(1 41 101 / 30%);
}

.searchBy:hover {
  background-color: #012965;
  color: #ffffff;
  border: solid 1px #012965;
}

.resetFilters {
  height: 100%;
  color: #fff;
  line-height: 1.3;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem 0.2rem 0.7rem;
  /* border: solid 1px #012965; */
  transition: 250ms ease-in-out;
  background-color: #012965;
  border: 1px solid rgb(1 41 101 / 30%);
}

.resetFilters:hover {
  background-color: #001536;
}

.searchbar {
  height: 26px;
  overflow: hidden;
  font-size: 0.76rem;
  line-height: 1.3;
  font-size: 0.8rem;
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
  border: 1px solid rgb(1 41 101 / 30%);
}

.searchbox {
  border: none;
  background: none;
  float: left;
  outline: none;
  color: #012965;
}

.searchIcon {
  padding: 0;
  float: right;
  font-family: "simple-line-icons";
  font-size: 14px;
  color: #012965;
  border: none;
  background: none;
  /* border-top-right-radius: 25px; */
  /* border-bottom-right-radius: 25px; */
  text-align: center;
  cursor: pointer;
}

.btn_delivery {
  color: #012965;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
  height: 26px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem 0.2rem 0.7rem;
  transition: background-color box-shadow 0.1s linear;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
}

.btn_delivery span i {
  padding-left: 10px;
}

.searchBy span i {
  padding-left: 10px;
}

.btn_delivery:hover {
  background-color: #012965;
  color: #ffffff;
  border: solid 1px #012965;
}

.btn_payment {
  color: #012965;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
  height: 26px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem 0.2rem 0.7rem;
  transition: background-color box-shadow 0.1s linear;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
}

.btn_payment span i {
  padding-left: 10px;
}

.btn_payment:hover {
  background-color: #012965;
  color: #ffffff;
  border: solid 1px #012965;
}

.btn_apply {
  color: #ffffff;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
  height: 26px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.2rem 1rem 0.2rem 1rem;
  transition: background-color box-shadow 0.1s linear;
  border: solid 1px #012965;
  background-color: #012965;
  transition: 250ms ease-in-out;
}

.btn_apply:hover {
  background-color: #001d47;
}

.btn_page {
  color: #012965;
  font-size: 0.8rem;
  padding: 0px 10px;
  height: 24px;
  border: solid 1px #012965;
  transition: 250ms ease-in-out;
  background-color: #fff;
  border: 1px solid rgb(1 41 101 / 30%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_page:focus-visible {
  outline: none;
}

.btn_page span i {
  padding-left: 10px;
}

.per_page {
  font-size: 13px;
  opacity: 60%;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.imageDropDownContainer {
  width: 100%;
  min-height: 300px;
  position: relative;
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
}

.uploaderDiv {
  width: 200px;
  height: 30px;
  background: white;
  border-radius: 0.1rem;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  border: 1px solid rgb(1 41 101 / 20%);
}

.uploaderDiv:hover {
  background-color: #012965;
}

.uploaderDiv:hover .addIcon {
  color: #fff;
}

.uploaderDiv:hover .addLabel {
  color: #fff;
}

.uploadedImage {
  width: 16.666%;
  height: 33.333%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  padding: 15px;
  position: relative;
  float: left;
}

.uploadedInner {
  border-radius: 0.1rem;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.deleteImgIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0000;
  background-color: rgb(255 255 255 / 20%);
  border-radius: 2px;
  visibility: hidden;
}

.uploadedImage:hover .deleteImgIcon {
  visibility: visible;
}

.deleteImgIcon:hover {
  background-color: rgb(1 41 101 / 20%);
}

.uploadedImage img {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
}

.addIcon {
  font-size: 18px;
  /* margin-bottom: 8px; */
  color: #012965;
  height: 24px;
}

.addLabel {
  font-family: var(--robotoC);
  font-size: 16px;
  color: #012965;
}

.tabContainer {
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 10px;
}

.tabHead {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  bottom: -1px;
  z-index: 1;
}

.tabBody {
  width: 100%;
  height: calc(100% - 48px);
  border: 1px solid rgb(1 41 101 / 20%);
  padding: 24px 14px;
  overflow-y: auto;
  border-radius: 0 0 2px 2px;
  position: relative;
}

.tabs {
  width: 33.33%;
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  background-color: rgb(1 41 101 / 5%);
  border: 1px solid rgb(1 41 101 / 20%);
  border-right: none;
  border-bottom: none;
  transition: all 250ms ease;
  color: #012965;
}

.tabs:last-child {
  border-right: 1px solid rgb(1 41 101 / 20%);
  border-radius: 0 2px 0 0;
}

.tabs:last-child {
  border-right: 1px solid rgb(1 41 101 / 20%);
  border-radius: 0 2px 0 0;
}

.tabs.active {
  background-color: #fff;
  border-bottom: none;
  border-left: 1px solid rgb(1 41 101 / 20%);
}

.headerOne {
  width: 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.headerOne select {
  height: 24px;
  width: 100%;
  border: 1px solid rgb(1 41 101 / 10%);
  color: #012965;
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 14px;
}

.headerOne select:focus-visible {
  outline: none;
}

.numberSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  width: 100%;
  margin-bottom: 5px;
}

.numberSection div {
  width: 32%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #012965;
  font-weight: 300;
  font-size: 14px;
  font-family: var(--robotoC);
  background-color: rgb(1 41 101 / 11%);
}

.headerTwo {
  width: 45%;
  display: flex;
  align-items: center;
}

.headerThree {
  width: 16%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.headerThree select {
  height: 24px;
  width: 100%;
  border: 1px solid rgb(1 41 101 / 10%);
  color: #012965;
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 14px;
}


.headerThree select:first-child {
  margin-bottom: 5px;
}

.headerThree select:focus-visible {
  outline: none;
}

.headerFour {
  width: 10%;
}

.headerFive {
  width: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.headerFive span {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #012965;
  font-size: 14px;
  transition: transform 250ms ease;
}

.headerFive span.active {
  transform: rotate(180deg);
}

.itemImage {
  width: 80px;
  height: 53px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
}
.itemImage img{
  max-width: 100%;
  max-height: 100%;
}

.itemTitle {
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.itemTitle p {
  margin: 0;
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 14px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.itemPrice {
  width: 130px;
  float: right;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: var(--robotoC);
  color: #012965;
}

.priceEnpander {
  color: #012965;
  font-size: 18px;
  transition: transform 250ms ease;
}

.itemPrice p {
  margin: 0;
  text-align: center;
  font-family: var(--robotoC);
  font-weight: 300;
  position: relative;
  font-size: 14px;
}

.page_link {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(1 41 101 / 30%);
  border-radius: 40px;
  color: #001d47;
  text-decoration: none;
  font-family: var(--robotoC);
  font-size: 14px;
  height: 24px;
  min-width: 24px;
  list-style: none;
  margin: 0 !important;
}

.page_link.active {
  background-color: #012965;
  color: #fff;
}

.page_link:hover {
  background-color: #012965;
  color: #fff;
}

.pricePopup {
  position: absolute;
  width: 180px;
  background-color: #fff;
  padding: 15px;
  z-index: 1;
  top: 134px;
  display: none;
}

.filterSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.filterSection .nav {
  width: 100%;
}

.paginationSection {
  display: flex;
  align-items: center;
}

div.combineField {
  width: 64%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.combineField div {
  width: 40%;
  background-color: transparent !important;
}

div.combineField input {
  width: 60%;
  border: 1px solid rgb(1 41 101 / 10%);
  height: 24px;
  font-size: 14px;
  color: #012965;
}

div.combineField input:focus-visible {
  outline: none;
}

.customSelect_item {
  height: 24px;
  width: 100%;
  border: 1px solid rgb(1 41 101 / 10%);
  color: #012965;
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 14px;
}

.filterSelect_item {
  height: 26px;
  width: 140px;
  border: 1px solid rgb(1 41 101 / 30%);
  color: #012965;
  font-family: var(--robotoC);
  font-weight: 300;
  font-size: 14px;
}

.TopOffer {
  margin-bottom: 5px;
}

span.updateButton {
  background-color: #012965;
  color: #fff;
  border-radius: 2px;
  font-family: var(--robotoC);
  font-size: 14px;
}

.conversion_rate {
  height: 26px;
  border: 1px solid rgb(1 41 101 / 30%);
  width: 60px;
  font-weight: 300;
  font-size: 14px;
}

.conversion_rate:focus-visible {
  outline: none;
}

.field_update_label {
  min-width: 36px;
  height: 26px;
  line-height: 28px;
  padding: 0px 8px;
  margin-right: 5px;
  border: none;
  background: transparent;
  color: rgb(1, 41, 101);
  font-size: 14px;
}

.field_update_btn {
  width: 28px;
  height: 26px;
  cursor: pointer;
  background-color: rgb(1, 41, 101);
  color: rgb(255, 255, 255);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_container {
  width: 100%;
  height: 410px;
  display: flex;
  overflow-y: auto;
}

.form_column {
  width: 25%;
  height: 100%;
  padding: 0 15px;
}

.columnItemContainer {
  border: 1px solid rgb(1 41 101 / 15%);
  border-radius: 2px;
  /* min-height: 100%; */
  background-color: #f6f9ff;
}

.item_group {
  height: 68px;
  padding: 10px 15px;
}

.form_label {
  font-size: 14px;
  color: #012965;
}

.item_group:nth-child(odd) {
  background: #E5EDF8;
}

.item_group:nth-child(even) {
  background: #F6F9FF;
}

.item_editable {
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100vw;
  right: 0;
  bottom: 0;
  height: 100vh;
  margin: 0;
  padding: 15px;
  background-color: rgb(255 255 255 / 100%);
}

.edit_item_header {
  color: #012965;
  display: flex;
  align-items: center;
  position: relative;
  height: 50px;
  padding: 0 0;
  margin: 0;
}

.pop_title {
  font-family: var(--robotoC);
  font-weight: 300;
  padding-left: 20px;
  font-size: 24px;
}

.popup_close {
  float: right;
  font-size: 35px;
  cursor: pointer;
  color: rgba(255, 0, 0, .2) !important;
  position: absolute;
  right: 4px;
}

.pop_logo {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  display: flex;
}

.pop_logo img {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.tab_footer_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  right: 70px;
  padding: 1px 26px 0px;
}

.leftArrow {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(1 41 101 / 20%);
  color: rgb(1 41 101 / 50%);
  border-radius: 50%;
  background-color: #fff;
  margin-right: 4px;
}

.leftArrow:hover {
  background-color: #012965;
  color: #fff;
}

.RightArrow {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(1 41 101 / 20%);
  color: rgb(1 41 101 / 50%);
  border-radius: 50%;
  background-color: #fff;
  margin-left: 4px;
}

.RightArrow:hover {
  background-color: #012965;
  color: #fff;
}

.footerMiddleSection {
  width: 40%;
  display: flex;
  justify-content: center;
}

.save_btn {
  width: 95px;
  border-radius: 2px;
  border: 1px solid rgb(1 41 101 / 20%);
  color: #012965;
  background-color: #fff;
  font-family: var(--robotoC);
  padding: 2px 15px;
}

.save_btn:hover {
  background-color: #012965;
  color: #fff;
}