*{
    margin: 0;
    padding: 0;
}

.container{
    height: calc(100vh - 65px);
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    overflow: scroll;
    display: flex;
    align-items: center;

}
.popupBody{
    display: flex;
}
.ImageCropper{
    width: 100%;
}
.cropbtn{
    margin-top: 15px;
    color: #012965;
    border: none;
    outline: initial !important;
    box-shadow: initial !important;
    box-shadow: 0px 0px 2px rgb(80, 80, 80) !important;
    transition: 200ms ease-in-out;
    padding: 3px 8px;
    width: 100%;
}
.cropbtn:hover{
    background-color: #012965;
    color: #ffffff;
}
.cropperContainer{
    background: white;
    padding: 20px;
    box-shadow: 0px 0px 15px  rgba(1, 41, 101, 0.483);
    width: 55%;
}