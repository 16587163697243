.checkout_Popup {
  background: #fff;
  border-radius: 2px;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #012965;
  width: calc(100% + 250px);
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 6;
}

.carting_head {
  display: flex;
  padding: 12px;
  height: 85px;
  width: 100%;
  position: relative;
}

.pageTitleSection {
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pageLogoHolder {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  overflow: hidden;
  float: left;
  margin-right: 15px;
}

.pageLogo {
  width: 100%;
  height: 100%;
}

.pageTitle {
  float: left;
  /* font-size: 18px; */
  font-weight: 300;
  font-family: var(--robotoC);
}

.closeCart {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: rgba(255, 0, 0, 0.2);
  border-radius: 2px;
  font-family: var(--robotoC);
  width: 27px;
  height: 27px;
  cursor: pointer;
  top: 25px;
  right: 42px;
  font-size: 40px;
  text-decoration: none;
  background-color: #fff;
}

.cartItemContainer {
  height: calc(100% - 185px);
  width: 100%;
  position: relative;
  padding: 0 100px;
  display: flex;
}

.checkout_section {
  width: 33.333%;
}

.sectionTitle {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--robotoC);
}

.sectionBody {
  width: 100%;
  height: calc(100% - 24px);
  background-color: #F8FAFF;
  border-right: 1px solid rgb(1 41 101 / 10%);
  padding: 15px 30px;
}

.checkout_section:last-child .sectionBody {
  border-right: none;
}

.itemPart {
  width: calc(100% - 300px);
  display: flow-root;
  float: left;
  padding-right: 24px;
}

.checkoutTimeline,
.timelinePoint {
  margin: 0;
  padding: 0;
  color: #012965;
  font-family: verdana;
  font-size: 14px;
}

.checkoutTimeline {
  display: flex;
  width: 50%;
  justify-content: space-between;
  position: absolute;
  top: 23px;
  left: 50%;
  transform: translate(-50%);
}

.checkoutTimeline:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  /* background-color: rgb(1 41 101 / 40%); */
  border-bottom: 1px solid rgb(1 41 101 / 20%);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: -1;
}

.timelinePoint {
  list-style-type: none;
  position: relative;
  cursor: pointer;
}

.timelinePoint:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid rgb(1 41 101 / 40%);
  /* transition: background-color 0.2s ease; */
}

.timelinePoint:hover:after {
  background-color: #ff0000;
  border: 2px solid #ff0000;
}

.timelinePoint.active:after {
  background-color: #ff0000;
  border: 2px solid #ff0000;
}

.timelinePoint span {
  position: absolute;
  top: calc(100% + 6px);
  left: 50%;
  transform: translate(-50%, 0);
  display: inline-block;
  text-align: center;
  width: 100px;
  font-family: var(--robotoC);
  font-size: 12px;
  font-weight: 300;
  color: #012965;
}

.deliveryMethodConatiner {
  width: 100%;
}

.method_head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgb(1 41 101 / 20%);
  padding: 0 12px;
  height: 42px;
  width: calc(100%);
  position: relative;
}

.method_head h2 {
  font-size: 18px;
  font-weight: 400;
  font-family: var(--robotoC);
  color: #012965;
}

.stdmethod_item {
  width: 100%;
  min-height: 80px;
  border: 1px solid rgb(1 41 101 / 10%);
  border-radius: 2px;
  margin-top: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px 0;
  background-color: #fff;
}

.stdmethod_item:first-child {
  margin-top: 0;
}

.payMethod_item {
  width: 100%;
  height: 30px;
  border: 1px solid rgb(1 41 101);
  border-radius: 2px;
  margin-top: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 0;
  background-color: #fff;
  float: left;
}

.payMethod_item:first-child {
  margin-top: 0;
}

.stdmethod_mark {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stdmethod_mark span {
  font-size: 20px;
  color: #012965;
}

.stdmethod_Desc {
  height: 100%;
  width: calc(100% - 110px);
  font-family: var(--robotoC);
  font-size: 16px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #012965;
  flex-direction: column;
}

.stdmethodTitle {
  width: 100%;
  font-family: var(--robotoC);
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  color: #012965;
}

.stdmethodSubTitle {
  width: calc(100%);
  font-family: var(--robotoC);
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: #012965;
  line-height: 17px;
}

.stdmethodRate {
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stdmethodRate span {
  font-family: var(--robotoC);
  font-size: 16px;
  font-weight: 400;
  color: #012965;
}

.deliveryAddressConatiner {
  float: left;
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;
}

.delivery_address {
  width: 100%;
  height: auto;
  border: 1px solid rgb(1 41 101 / 10%);
  border-radius: 2px;
  margin-top: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px 0;
  background-color: #fff;
}

.delivery_address:first-child {
  margin-top: 0px;
}

.addresses {
  height: 100%;
  width: calc(100% - 110px);
  font-family: var(--robotoC);
  font-size: 16px;
  font-weight: 300;
  color: #012965;
}

.addresses h2 {
  font-family: var(--robotoC);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.addresses p {
  font-family: var(--robotoC);
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.addNewAddress {
  width: 100%;
  float: left;
  cursor: pointer;
  font-family: var(--robotoC);
  font-size: 16px;
  font-weight: 300;
  color: #012965;
  height: 40px;
  display: flex;
  align-items: center;
}

.paymentMethodConatiner {
  float: left;
  width: 100%;
  height: 100%;
}

.paymentMIcon {
  width: 130px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
}

.paymentMIcon span {
  width: 35px;
  height: 28px;
  background-position: center;
  background-size: cover;
}

.paymentMIcon .visaCa {
  background-image: url("../visa.png");
}

.paymentMIcon .masterCa {
  background-image: url("../masterCard.png");
}

.paymentMIcon .aExpressCa {
  background-image: url("../aExpress.png");
}

.paymentMIcon .paypalCa {
  background-image: url("../paypal.png");
}

.paymentMIcon .bKashCa {
  background-image: url("../bkash.webp");
}

.paymentMIcon .stripe {
  /* background-image: url("../images/pay/paypal.png"); */
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmationTitle {
  width: calc(100%);
  height: auto;
  border-radius: 2px;
  margin-top: 20px;
  cursor: pointer;
  display: block;
  float: left;
  position: relative;
  padding: 10px;
}

.confirmationTitle h2 {
  font-family: var(--robotoC);
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  margin-top: 12px;
}

.confirmationTitle p {
  font-family: var(--robotoC);
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  margin-top: 10px;
}

.billing_address {
  width: calc(100%);
  height: auto;
  border: 1px solid rgb(1 41 101 / 10%);
  border-radius: 2px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  float: left;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  padding: 40px;
  /* margin-right: 10px; */
}

.payment_currency {
  width: calc(100%);
  height: auto;
  border-radius: 2px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  float: left;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  padding: 10px 0;
}

.payment_currency .stdmethod_item {
  margin-top: 0;
  width: calc(50% - 25px);
}

.billing_address .addresses {
  width: calc(100% - 100px);
}

.billing_address .addresses h2 {
  font-weight: 300;
}

.billing_address .addressProced {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.billing_address .addressProced button {
  background: #ff0000;
  font-family: var(--robotoC);
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  margin-top: 25px;
}

.totalPaymentAmount {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: var(--robotoC);
  color: #ff0000;
}

.saved_card_container {
  width: 50%;
  height: 100%;
  padding: 40px 0;
}
.saved_cards {
  padding: 0;
  height: calc(100% - 35px);
  width: 100%;
  overflow-y: auto;
}

.card_item {
  width: 100%;
  min-height: 50px;
  border: 1px solid rgb(1 41 101 / 10%);
  border-radius: 2px;
  /* margin-bottom: 15px; */
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 6px 0;
  background-color: #fff;
  border-bottom: 0;
}

.card_item:last-child {
  /* margin-bottom: 0; */
  border-bottom: 1px solid rgb(1 41 101 / 10%);
}

.card_left {
  height: 100%;
  width: 85%;
  display: flex;
  align-items: center;
}
.card_logo {
  width: 40px;
  height: 40px;
  display: flex;
  /* margin-left: 12px; */
}
.card_logo img{
  max-width: 100%;
  max-height: 100%;
}
.card_details {
  margin-left: 12px;
  height: 100%;
  width: calc(100% - 87px);
}
.card_details p{
  font-family: var(--robotoC);
  color: #012965;
  line-height: 18px;
  font-size: 14px;
  font-weight: 300;
}
.card_right {
  height: 100%;
  width: 15%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 0 10px;
}
.card_right p{
  line-height: 24px;
  color: #ff0000;
}

.stripe_form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stripeContainer {
  width: calc(100%);
  height: calc(100% - 30px);
  border: 1px solid rgb(1 41 101 / 10%);
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  float: left;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  background-color: #F8FAFF;
}

.rememberContainer {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 400;
  display: block;
  position: relative;
  padding-left: 35px;
  color: #012965;
  margin-bottom: 12px;
  margin-top: 8px;
  cursor: pointer;
  line-height: 19px;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.rememberContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

/* On mouse-over, add a grey background color */
.rememberContainer:hover input~.checkmark {
  background-color: #ff0000;
}

/* When the checkbox is checked, add a blue background */
.rememberContainer input:checked~.checkmark {
  background-color: #ff0000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.rememberContainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.rememberContainer .checkmark:after {
  left: 7px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.markerDot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid rgb(1 41 101 / 20%);
  background-color: #FFF;
}

.markerDot.active {
  background-color: #012965;
}

.confirmationConatiner {
  float: left;
  width: 45%;
  /* margin-top: 20px; */
}

.bKashContainer {
  width: 90%;
  height: auto;
  margin: 40px;
  /* background-color: antiquewhite; */
}

.bKashlabel {
  font-size: 16px;
  font-weight: 300;
  font-family: var(--robotoC);
  color: #012965;
}

.bKashAccount {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--robotoC);
  color: #012965;
  line-height: 40px;
}

.bKashInputContainer {
  width: 100%;
}

.bKashInput {
  width: 70%;
  border-radius: 2px;
  border: 1px solid rgb(1 41 101 / 20%);
  height: 30px;
  padding: 5px 10px;
}

.bKashInput:focus-within {
  outline: none;
}

.bkashPlaceOrder {
  background: #ff0000;
  font-family: var(--robotoC);
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  margin-top: 25px;
}

.addressSelectionBtn {
  width: calc(50% - 15px);
  height: 35px;
  border: none;
  background-color: #012965;
  color: #FFF;
  border-radius: 2px;
  font-size: 16px;
  font-family: var(--robotoC);
  cursor: pointer;
}

.carting_footer {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}

.proceed_to_chec {
  width: auto;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  background-color: #ff0000;
  border-radius: 2px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-family: var(--robotoC);
  font-weight: 300;
  padding: 0 85px;
  margin-top: 25px;
}

.paymentConatiner {
  float: left;
  width: 100%;
  height: calc(100% - 185px);
  display: flow-root;
  padding: 0 100px;
}

.paymentSuccess {
  width: 100%;
  height: 100%;
  background-color: #F8FAFF;
  padding: 40px 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}


.cartTabContainer {
  width: 100%;
  height: 145px;
  display: flow-root;
}

.tabContainer {
  width: 100%;
  height: calc(100%);
  display: flow-root;
}

.tabHead {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  bottom: -1px;
}

.tabs {
  width: 33.33%;
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  background-color: rgb(1 41 101 / 5%);
  border: 1px solid rgb(1 41 101 / 20%);
  border-right: none;
  border-bottom: none;
  transition: all 250ms ease;
  color: #012965;
}

.tabs:first-child {
  border-radius: 2px 0 0 0;
}

.tabs:last-child {
  border-right: 1px solid rgb(1 41 101 / 20%);
  border-radius: 0 2px 0 0;
}

.tabs.active {
  background-color: #fff;
  border-bottom: none;
  border-left: 1px solid rgb(1 41 101 / 20%);
}

.tabBody {
  width: 100%;
  height: calc(100% - 48px);
  background-color: #fff;
  border: 1px solid rgb(1 41 101 / 20%);
  padding: 25px 35px;
  border-radius: 0 0 2px 2px;
}

.tabBody p {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #012965;
  text-align: left;
}

.successText_1 {
  width: 100%;
  text-align: center;
  font-family: var(--Poppins);
  font-size: 72px;
  font-weight: 100;
  color: #ff0000;
  line-height: 72px;
}

.successText_2 {
  width: 100%;
  text-align: center;
  font-family: var(--robotoC);
  font-size: 21px;
  font-weight: 300;
  color: rgb(1 41 101 / 70%);
  line-height: 60px;
}

.successText_3 {
  width: 100%;
  text-align: center;
  font-family: var(--robotoC);
  font-size: 21px;
  font-weight: 300;
  color: rgb(1 41 101 / 70%);
  line-height: 26px;
  padding: 25px 0;
}

.savedCards {
  width: 100%;
  height: calc(100% - 164px);
  margin-top: 5px;
  margin-bottom: 35px;
  float: left;
}

.add_new_card {
  min-height: 24px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-family: var(--robotoC);
  font-weight: 300;
  padding: 0 5px;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.card_item .stdmethod_mark {
  width: 35px;
}

@media (max-width: 768px) {
  .checkout_Popup {
    padding: 60px 10px 10px;
  }

  .checkoutTimeline {
    width: calc(100% - 160px);
    left: 30%;
    transform: translate(-30%);
  }

  .carting_head {
    width: calc(100%);
  }

  .closeCart {
    top: 12px;
    right: 12px;
    font-size: 30px;
  }

  .itemPart {
    width: calc(100%);
    padding-right: 0;
  }

  .delivery_address {
    width: 100%;
    margin-right: 0px;
  }

  .delivery_address:nth-child(2n) {
    margin-left: 0;
  }

  .payment_currency {
    flex-direction: column;
  }

  .payment_currency .stdmethod_item {
    width: 100%;
  }

  .payment_currency .stdmethod_item:first-child {
    margin-bottom: 10px;
  }

  .addressSelectionBtn {
    width: 100%;
  }

  .addressSelectionBtn:first-child {
    margin-bottom: 15px;
  }
}