.top_bar {
  width: 100%;
  background-color: #fff;
  /* background-image: repeating-linear-gradient(1deg,rgba(1,41,101,.6),rgba(1,41,101,.6) 1px,transparent 0,transparent 4px,rgba(1,41,101,.6) 0),repeating-linear-gradient(91deg,rgba(1,41,101,.6),rgba(1,41,101,.6) 1px,transparent 0,transparent 4px,rgba(1,41,101,.6) 0),repeating-linear-gradient(181deg,rgba(1,41,101,.6),rgba(1,41,101,.6) 1px,transparent 0,transparent 4px,rgba(1,41,101,.6) 0),repeating-linear-gradient(271deg,rgba(1,41,101,.6),rgba(1,41,101,.6) 1px,transparent 0,transparent 4px,rgba(1,41,101,.6) 0); */
  border: 1px solid rgba(1, 41, 101, .1);
  box-shadow: 0 1px 1px -1px rgb(1 41 101 / 12%), 0 1px 1px -1px rgb(1 41 101 / 7%);
  box-sizing: border-box;
  height: 24px;
  padding: 0 9px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.head_right {
  width: 40%;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.menu_user {
  width: auto;
  height: 24px;
  white-space: nowrap;
  background: transparent;
  border-radius: 2px;
  float: right;
  cursor: pointer;
  display: flex;
  position: relative;
  top: 8px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.menu_user span {
  text-align: center;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  background-color: transparent;
  color: #012965;
  width: 100%;
  height: 24px;
  border-radius: 2px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease;
}

.user_img {
  width: 24px;
  height: 24px;
  padding: 0 3px;
  float: right;
  cursor: pointer;
  padding-bottom: 3px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #012965;
}

.addNewItem {
  min-width: 108px;
  height: 20px;
  color: rgb(255 0 0 / 80%);
  cursor: pointer;
  background-color: transparent;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  text-align: center;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-decoration: none;
}

.addNewItem:hover {
  color: #ffffff;
  background-color: rgb(255 0 0 / 80%);
}