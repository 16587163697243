.addItemPopup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  right: 0;
  top: 0;
  background-color: #fff;
  z-index: 9;
  padding: 0 20px 0;
}

.itemDesIcon {
  float: right;
  font-size: 35px;
  cursor: pointer;
  color: rgba(255, 0, 0, .2) !important;
  position: absolute;
  right: 0;
}

.itemDes {
  text-align: left;
  color: #012965;
  display: flex;
  align-items: center;
  position: relative;
}

.pop_logo {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  display: flex;
}

.pop_logo img {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.pop_title {
  font-family: var(--robotoC);
  font-weight: 300;
  padding-left: 20px;
}

.input_item {
  height: 24px;
  padding: 0 10px !important;
  font-family: var(--robotoC) !important;
  font-size: 15px !important;
  color: #012965;
  font-weight: 300;
}

.item_createBtn {
  height: 24px;
  border-radius: 2px !important;
  padding: 0;
}

.image_container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  min-height: 108px;
  max-height: 200px;
  flex-wrap: wrap;
}

/* Tab Design */


.tabContainer {
  width: 100%;
  height: calc(100vh - 90px);
  margin-top: 10px;
}

.tabHead {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  bottom: -1px;
}

.tabBody {
  width: 100%;
  height: calc(100% - 48px);
  border: 1px solid rgb(1 41 101 / 20%);
  padding: 24px 0;
  overflow-y: auto;
  border-radius: 0 0 2px 2px;
}

.tabs {
  width: 33.33%;
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  background-color: rgb(1 41 101 / 5%);
  border: 1px solid rgb(1 41 101 / 20%);
  border-right: none;
  border-bottom: none;
  transition: all 250ms ease;
  color: #012965;
}

.tabs:last-child {
  border-right: 1px solid rgb(1 41 101 / 20%);
  border-radius: 0 2px 0 0;
}

.tabs:last-child {
  border-right: 1px solid rgb(1 41 101 / 20%);
  border-radius: 0 2px 0 0;
}

.tabs.active {
  background-color: #fff;
  border-bottom: none;
  border-left: 1px solid rgb(1 41 101 / 20%);
  color: #012965;
}


.tab_footer_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 24px 25px 0;
}

.leftArrow {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(1 41 101 / 20%);
  color: rgb(1 41 101 / 50%);
  border-radius: 50%;
  background-color: #fff;
}

.leftArrow:hover {
  background-color: #012965;
  color: #fff;
}

.RightArrow {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(1 41 101 / 20%);
  color: rgb(1 41 101 / 50%);
  border-radius: 50%;
  background-color: #fff;
}

.RightArrow:hover {
  background-color: #012965;
  color: #fff;
}

.footerMiddleSection {
  width: 40%;
  display: flex;
  justify-content: center;
}

.save_btn {
  width: 95px;
  border-radius: 2px;
  border: 1px solid rgb(1 41 101 / 20%);
  color: #012965;
  background-color: #fff;
  font-family: var(--robotoC);
  padding: 2px 15px;
}

.save_btn:hover {
  background-color: #012965;
  color: #fff;
}

.imageDropDownContainer {
  width: 100%;
  min-height: 300px;
  position: relative;
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
}

.uploaderDiv {
  width: 205px;
  height: 30px;
  background: white;
  border-radius: 0.1rem;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  position: absolute;
  /* left: 0px; */
  bottom: 42px;
  border: 1px solid rgb(1 41 101 / 20%);
}

.uploaderDiv:hover {
  background-color: #012965;
}

.uploaderDiv:hover .addIcon {
  color: #fff;
}

.uploaderDiv:hover .addLabel {
  color: #fff;
}

.uploadedImage {
  width: 16.666%;
  height: 33.333%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  padding: 15px;
  position: relative;
  float: left;
}

.uploadedInner {
  border-radius: 0.1rem;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadedImage img {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
}

.addIcon {
  font-size: 18px;
  /* margin-bottom: 8px; */
  color: #012965;
  height: 24px;
}

.addLabel {
  font-family: var(--robotoC);
  font-size: 16px;
  color: #012965;
}

.form_container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
}

.form_column {
  width: 25%;
  height: 100%;
  padding: 0 25px;
}

.columnItemContainer {
  border: 1px solid rgb(1 41 101 / 15%);
  border-radius: 2px;
  /* min-height: 100%; */
  background-color: #f6f9ff;
}

.item_group {
  height: 68px;
  padding: 10px 15px;
}

.form_label {
  font-size: 14px;
  color: #012965;
}

.item_group:nth-child(odd) {
  background: #E5EDF8;
}

.item_group:nth-child(even) {
  background: #F6F9FF;
}