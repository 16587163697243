.payment_page {
    height: 100%;
    width: 100%;
    position: relative;
}

.payment_card_section {
    margin-bottom: 15px;
    height: 100px;
}

.payment_table_section {
    height: calc(100% - 115px);
}

.table_heads {
    background-color: #ececec;
}

.payment_popup_wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.431);
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Payment Form Styles */

.payment_form {
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Montserrat', sans-serif;
    position: relative;
}

.payment_form h2 {
    text-align: center;
    margin-bottom: 1rem;
    color: #333;
    font-weight: 600;
    font-size: 26px;
}

.payment_form p {
    text-align: center;
    color: #666;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
}

.amount_input_container {
    height: 63px;
    margin-bottom: 1rem;
    padding: 10px;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
}

.amount_input_field {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
}

.amount_input_field:focus-visible {
    outline: none;
}

.card_element_container {
    height: 63px;
    margin-bottom: 1rem;
    padding: 10px;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
}

.pay_button {
    width: 100%;
    padding: 0.75rem;
    background-color: #4caf50;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pay_button:hover {
    background-color: #45a049;
}

.pay_button:disabled {
    background-color: #9e9e9e;
    cursor: not-allowed;
}

.close_payment {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -35px;
    color: #000;
    top: 0;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
}