.backwrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(1 41 101 / 30%);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
}
.Order_details_popup {
    /* width: 80%;
    height: 80%;
    background-color: #fff;
    border-radius: 2px; */
    position: fixed;
    width: 100vw;
    height: 100vh;
    right: 0;
    top: 0;
    background-color: #fff;
    z-index: 9;
    padding: 0 20px 0;
}
.header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(1 41 101 / 30%);
    padding: 10px 30px;
    position: relative;
}
.header h3{
    font-size: 22px;
    margin: 0;
}

.itemDesIcon {
  float: right;
  font-size: 35px;
  cursor: pointer;
  color: rgba(255, 0, 0, .2) !important;
  position: absolute;
  right: 0;
}

.itemDes {
  text-align: left;
  color: #012965;
  display: flex;
  align-items: center;
  position: relative;
}

.pop_logo {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  display: flex;
}

.pop_logo img {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.pop_title {
  font-family: var(--robotoC);
  font-weight: 300;
  padding-left: 20px;
}

.closePopup {
    float: right;
    font-size: 35px;
    cursor: pointer;
    color: rgba(255, 0, 0, .2) !important;
    position: absolute;
    right: 25px;
  }
.body {
    width: 100%;
    height: calc(100% - 60px)
}
.tab_container {
    width: 100%;
    height: calc(100vh - 90px);
    margin-top: 10px;
}
.tab_header {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    bottom: -1px;
}
.tab_head_item {
    width: 33.33%;
    font-family: var(--robotoC);
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    background-color: rgb(1 41 101 / 5%);
    border: 1px solid rgb(1 41 101 / 20%);
    border-right: none;
    border-bottom: none;
    transition: all 250ms ease;
    color: #012965;
}
.tab_head_item.active {
    background-color: #fff;
    border-bottom: none;
    border-left: 1px solid rgb(1 41 101 / 20%);
    color: #012965;
}
.tab_head_item:last-child{
    border-right: 1px solid rgb(1 41 101 / 20%);
    border-radius: 0 2px 0 0;
}
.tab_body {
    width: 100%;
    height: calc(100% - 48px);
    border: 1px solid rgb(1 41 101 / 20%);
    padding: 24px 0;
    overflow-y: auto;
    border-radius: 0 0 2px 2px;
}
.tab_data_container{
  padding: 20px 40px; 
  width: 100%;
}
.confirmation_text{
  color:#dc3545;
  font-size: 19px;
}
.delivery_at{
  display: flex;
  height: 100%;
  justify-content: flex-end;
}
.delivery_at_text{
  width: 240px;
    color: #012965;
    border:solid 1px #0129655a;
    font-weight: 200;
    text-align: center;
    display: flex;
    font-size: 18px;
    vertical-align: baseline;
    align-items: center;
    padding: 0px 40px;
    background-color: rgb(1 41 101 / 5%);
    height: 60px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
p{
  margin: 0;
}
.callbtn{
    display: flex;
  height: 100%;
  justify-content: flex-end;
  }
  .callbtn i:hover{
    background: #fff;
    color: #012965;
    border:solid 1px #0129655a;
  }
  .callbtn i{
    min-width: 240px;
    text-align: center;
    height: 60px;
    color: white;
    font-weight: 200;
    text-align: center;
    font-size: 34px;
    vertical-align: baseline;
    align-items: center;
    padding: 0px 40px;
    background-color: #012965;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 100ms ease-in-out;
  }
  .logoAndText{
    display: flex;
  }
  .logoAndText i{
    font-size: 28px;
    padding-right: 10px;
  }
  .order_for{
  width: 240px;
    color: #012965;
    font-weight: 200;
    text-align: center;
    display: flex;
    font-size: 18px;
    vertical-align: baseline;
    align-items: center;
    height: 60px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
     border:solid 1px #0129655a;
     padding-bottom: 5px;
     background-color: rgb(1 41 101 / 5%);
}
.order_for span{
  margin-top: -8px;
  font-size: 22px;
  font-weight: 400;
}
.InvoiceDelivery_text{
  background-color: #012965;
  color: white;
  width: 120px;
  text-align: center;
  padding: 1px 8px;
}
.InvoiceDelivery_text p{
  padding-bottom: 2px;
}
.invoice_text{
  color: #012965a2;
  display: flex;
  height: 100%;
  justify-content: flex-end;
}
.orderStep{
  list-style: none !important;
  font-size: 18px;
  line-height: 24px;
}
.orderStep li::before{
  content: "•";
  font-size: 60px;
  position: absolute;
  left: -10.5px;
  top: -8px;
  color: #012965;
}
.orderStep li{
    border-left: 2px solid #012965a2;
    padding: 0 0 1.6px 32px;
    position: relative;
}
.orderStep li:last-child{
  border: 2px solid transparent;
}
.tab_data_container .scroll{
  height: calc(100vh - 70px - 220px);


}
.message_span{
  font-size: 14px;
  color: #0129657c;
}
.chat_text_left{
  padding-left: 64px;
}
.chatText{
  font-size: 18px;
  margin-bottom: 0;
  margin-left: .5rem !important;
  color: #012965c0;
}
.positionStatic{
  position: static;
  overflow: hidden !important;
  overflow-anchor: none;
  touch-action: auto;
}
.sendMessageBox{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100%;
  height: 90px;
  background: white;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  transition: padding 300ms;
  padding: 10px;
  padding-right: 0;
}
.chatInput:focus{
  box-shadow: none !important;
}
.chatInput{
  height: 80px; 
  width: 90%; 
  border: none;
}
.msgSendBtn{
  height: 90px;
  width: 90px;
  border: none;
  transition: 500ms ease-in-out;
  background: #0129652c;
}
.msgSendBtn i{
  font-size: 30px;
}
.msgSendBtn:hover{
  background-color: #012965;
  color: white;
}

