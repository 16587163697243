
.sign_in_page_container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.popup_nav,
.page_nav {
  width: 100%;
  height: 20px;
  background-color: transparent;
  /* border-bottom: 1px solid #e7eaee; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 25px;
  letter-spacing: 0.1em;
}

.popup_nav {
  height: 30px;
  position: relative;
}

.popup_nav span,
.page_nav span {
  font-size: 12px;
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  margin-right: 5px;
  line-height: 20px;
  user-select: none;
  color: #012965;
}

.popup_nav a,
.page_nav a {
  text-decoration: none;
  color: #012965;
}

.closeCart {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: rgba(255, 0, 0, 0.2);
  border-radius: 2px;
  font-family: var(--robotoC);
  width: 27px;
  height: 27px;
  cursor: pointer;
  top: 25px;
  right: 36px;
  font-size: 40px !important;
  background-color: #fff;
}

.signin_partition {
  display: flow-root;
  height: 100%;
  width: 100%;
  background: rgb(255 255 255 / 97%);
  border-radius: 2px;
}

.form_content {
  display: flex;
  height: calc(100% - 31px);
  align-items: center;
  justify-content: center;
  /* background-image: url('../images/popup_side_img-min.png');
  background-repeat: no-repeat; */
}

.signin_form {
  width: 350px;
  min-height: 286px;
  border-right: 1px solid rgb(255 0 0 / 20%);
  padding: 0 40px;
}

.signin_form:last-child {
  border-right: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.storeSignin {
  min-height: 331px;
}

.signupLast {
  display: flow-root !important;
}

.signUp_form {
  width: 400px;
  float: left;
  max-height: calc(100% - 100px);
  overflow-y: auto;
}

.countryList {
  width: 270px !important;
}

.inputCont {
  padding: 0 40px;
  float: left;
  width: 350px;
  position: relative;
}

.inputContSignin {
  width: 100%;
  position: relative;
}

.viewPassword {
  position: absolute;
  right: 1px;
  top: 9px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-left: 1px solid rgb(255 0 0 / 10%);
}

.inputCont .viewPassword {
  right: 41px;
}

.signup_title {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-family: var(--robotoC);
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 30px;
  color: #012965;
  text-align: left;
  padding: 0 40px;
}

h1.signup_title span {
  font-size: 14px;
  margin-top: 5px;
}

.signin_title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-family: var(--robotoC);
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 30px;
  color: #012965;
}

h1.signin_title span {
  font-size: 14px;
  margin-top: 5px;
}

.sign-in-page-container form {
  font-size: 16px;
  font-weight: 600;
}

.inputs {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  height: 42px !important;
  width: 100% !important;
  padding: 8px 20px;
  margin: 8px 0 15px;
  display: inline-block;
  color: #012965;
  letter-spacing: 0.2px;
  border: 1px solid rgb(255 0 0 / 10%) !important;
  box-sizing: border-box;
  border-radius: 2px !important;
}

.dropdown {
  font-family: var(--robotoC);
  border: 1px solid rgb(255 0 0 / 10%)
}

.codeDropDown {
  border: 1px solid rgb(255 0 0 / 10%) !important;
  background-color: #ffffff !important;
}

.inputs:focus-visible {
  border: 1px solid rgb(255 0 0 / 30%) !important;
  outline: none;
}

.inputs:-webkit-autofill {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.errMsg {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 12px;
  color: #ff0000;
  position: absolute;
  left: 40px;
  bottom: -3px;
}



.phoneInput {
  margin: 8px 0 15px;
}

.signin_button {
  background-color: #ffffff;
  color: #ff0000;
  height: 28px;
  padding: 0px 20px;
  margin-top: 8px;
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  border: 1px solid rgba(255, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--robotoC);
}

.signin_button span{
  font-family: var(--robotoC);
}

.signin_button:hover {
  background-color: #ff0000;
  color: #fff;
}

.signin_button:hover {
  opacity: 0.8;
}

.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f23065;
}

.signup_button {
  width: auto;
  padding: 10px 18px;
  border: 1px soid f23065;
}

.buttoninactive:hover {
  opacity: 0.5;
}

.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

.avatar {
  width: 40%;
  border-radius: 50%;
}

.psw {
  width: 100%;
  text-align: end;
  display: block;
  line-height: 28px;
}

.psw a {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  color: #012965;
}

.psw a:hover {
  text-decoration: underline;
}

.rememberContainer {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  display: block;
  position: relative;
  padding-left: 35px;
  color: #012965;
  margin-bottom: 12px;
  margin-top: 8px;
  cursor: pointer;
  line-height: 19px;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rememberContainer a{
  font-family: var(--robotoC);
}

/* Hide the browser's default checkbox */
.rememberContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

/* On mouse-over, add a grey background color */
.rememberContainer:hover input~.checkmark {
  background-color: #ff0000;
}

/* When the checkbox is checked, add a blue background */
.rememberContainer input:checked~.checkmark {
  background-color: #ff0000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.rememberContainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.rememberContainer .checkmark:after {
  left: 7px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.signintextMsg {
  font-family: var(--robotoC);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 30px;
  color: #012965;
}

.spiner {
  margin-left: 10px;
  display: none;
}

.onProcoess {
  pointer-events: none;
}

.onProcoess .spiner {
  display: inline-block;
}

button[disabled] {
  background: #ffffff;
}

.inputLabel {
  color: #012965;
  font-size: 15px;
  font-family: var(--robotoC);
  margin-bottom: 10px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
}

.resendBTN {
  cursor: pointer;
}


.opt_container {
  width: 600px;
  padding: 60px 80px;
  background-color: #fff;
  border: 1px solid rgb(255 0 0 / 40%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main_title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  font-family: var(--robotoC);
  margin-bottom: 20px;
  color: #012965;
}

.sub_title {
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  font-family: var(--robotoC);
  margin-bottom: 20px;
  color: #012965;
  line-height: 24px;
}

.message_box {
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  font-family: var(--robotoC);
  margin-bottom: 40px;
  color: #012965;
  line-height: 24px;
}

.opt_label {
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  font-family: var(--robotoC);
  color: #012965;
  line-height: 18px;
}

.opt_input_container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 10px 0;
  margin-bottom: 30px;
  position: relative;
  width: 301px;
}

.opt_input {
  border-radius: 2px;
  border: 1px solid rgb(255 0 0 / 30%);
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  color: #012965;
  font-family: var(--robotoC);
}

.opt_resend {
  text-align: end;
  font-size: 12px;
  font-weight: 200;
  font-family: var(--robotoC);
  color: #fff;
  line-height: 14px;
  position: absolute;
  width: auto;
  right: 6px;
  bottom: -14px;
  border-radius: 2px;
  cursor: pointer;
}

.resendBTN {
  text-align: end;
  font-size: 12px;
  font-weight: 300;
  font-family: var(--robotoC);
  color: #ff0000;
  line-height: 14px;
  width: auto;
  border: 1px solid rgb(255 0 0 / 30%);
  padding: 2px 5px;
  border-radius: 2px;
  cursor: pointer;
}

.resendBTN:hover {
  color: #fff;
  background-color: #ff0000;
}

.resendText {
  text-align: end;
  font-size: 12px;
  font-weight: 300;
  font-family: var(--robotoC);
  color: #012965;
  line-height: 14px;
  width: auto;
  background-color: #fff;
  padding: 2px 5px;
  border-radius: 2px;
  cursor: pointer;
}

.opt_btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-family: var(--robotoC);
}

.btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.opt_verify_btn {
  color: #ff0000;
  border: 1px solid rgb(255 0 0 / 30%);
  background-color: #fff;
  padding: 0 16px;
  font-size: 15px;
  height: 26px;
  border-radius: 2px;
  min-width: 100px;
  cursor: pointer;
}

.opt_verify_btn:hover {
  background-color: #ff0000;
  color: #fff;
}

.opt_spam_label {
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  font-family: var(--robotoC);
  color: #012965;
  line-height: 12px;
}

/* Change styles for span and cancel button on extra small screens */
@media (max-width: 768px) {
  .form_content {
    display: flow-root;
    overflow-y: auto;
  }

  .signin_form {
    border-right: none;
    padding: 20px;
    width: calc(100% - 40px);
    margin: 0 auto;
    display: flow-root;
  }

  .signup_title {
    width: 100%;
    margin-bottom: 20px;
  }

  .signUp_form {
    width: 100%;
    /* overflow-y: auto; */
    padding: 30px 0;
  }

  .inputCont {
    width: 100%;
  }

  .closeCart {
    top: 12px;
    right: 12px;
    font-size: 30px;
  }

  .popup_nav {
    padding: 5px 12px;
    height: 20px;
  }

  .signin_title {
    margin-bottom: 20px;
  }

  .inputs {
    margin-top: 0;
  }

  .viewPassword {
    top: 0;
  }

  .psw {
    line-height: 20px;
  }

  .signin_button {
    background-color: #ff0000;
    color: #ffffff;
  }

  .signin_form:first-child {
    margin-bottom: 30px;
  }

  .signin_form:last-child {
    min-height: 182px;
    margin-top: 30px;
    position: relative;
  }

  .signin_form:last-child::before {
    content: '';
    height: 1px;
    background-color: rgba(255, 0, 0, 0.2);
    width: calc(100% + 40px);
    position: absolute;
    top: -30px;
    left: -20px;
  }

  .signintextMsg {
    margin-bottom: 20px;
  }
}