.order_container {
    width: 100%;
    height: 100%;
}

.itemsPageHeader {
    width: 100%;
    height: 45px;
    background-color: transparent;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pageHeaderSection {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pageHeaderSection:first-child {
    justify-content: flex-start;
}

.titleWithBC {
    display: flex;
    align-items: center;
    height: 100%;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    margin: 0;
    padding-left: 8px;
}

.breadcrumb_item {
    font-weight: 100;
    padding-right: 8px;
    display: list-item;
    text-align: -webkit-match-parent;
}

.breadcrumb_item+.breadcrumb_item::before {
    display: inline-block;
    padding-right: 8px;
    color: #012965;
    content: "|";
}

.breadcrumb_item:last-child {
    opacity: 80%;
}


.filterSection {
    width: 100%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.filterSelect {
    margin-right: 15px;
    height: 24px;
}

.filterSelect select {
    width: 160px;
    height: 24px;
    border-radius: 2px;
    border: 1px solid rgba(1, 41, 101, 0.3);
    color: #012965;
    font-weight: 300;
}

.filterSelect select:focus-visible {
    outline: none;
}

.filterSearch {
    height: 24px;
}

.filterSearch input {
    width: 180px;
    height: 24px;
    border-radius: 2px;
    border: 1px solid rgba(1, 41, 101, 0.3);
    padding: 0 10px;
    color: #012965;
    font-weight: 300;
}

.filterSearch input:focus-visible {
    outline: none;
}

.nav {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: space-between;
}

.nav_item_left {
    display: flex;
    flex-wrap: wrap;
}

.nav_item_right {
    display: flex;
    flex-wrap: wrap;
}

.navItem {
    padding-left: 6px;
}

.navItem:first-child {
    padding-left: 0;
}

.searchBy {
    height: 26px;
    width: 150px;
    border: 1px solid rgb(1 41 101 / 30%);
    color: #012965;
    font-family: var(--robotoC);
    font-weight: 300;
    font-size: 14px;
}

/* .searchBy:hover {
    background-color: #012965;
    color: #ffffff;
    border: solid 1px #012965;
} */

.searchbar {
    display: flex;
    align-items: center;
    height: 26px;
    overflow: hidden;
    padding: 0 8px;
    font-size: 12px;
    transition: 250ms ease-in-out;
    border: 1px solid rgb(1 41 101 / 30%);
}

.searchbox {
    border: none;
    background: none;
    float: left;
    outline: none;
}

.searchIcon {
    padding: 0;
    float: right;
    font-family: "simple-line-icons";
    font-size: 14px;
    color: #012965;
    border: none;
    background: none;
    /* border-top-right-radius: 25px; */
    /* border-bottom-right-radius: 25px; */
    text-align: center;
    cursor: pointer;
}

.btn_delivery {
    height: 26px;
    border: 1px solid rgb(1 41 101 / 30%);
    color: #012965;
    font-family: var(--robotoC);
    font-weight: 300;
    font-size: 14px;
    padding: 0 !important;
}

.btn_delivery span i {
    padding-left: 10px;
}

.searchBy span i {
    padding-left: 10px;
}

.btn_payment {
    height: 26px;
    border: 1px solid rgb(1 41 101 / 30%);
    color: #012965;
    font-family: var(--robotoC);
    font-weight: 300;
    font-size: 14px;
    padding: 0 !important;
}

.btn_payment span i {
    padding-left: 10px;
}

/* .btn_payment:hover {
    background-color: #012965;
    color: #ffffff;
    border: solid 1px #012965;
} */

.btn_apply {
    height: 26px;
    padding: 0 15px;
    background-color: #012965;
    border: 1px solid #01296569;
    color: #ffffff;
    font-family: var(--robotoC);
    font-size: 0.8rem;
    transition: 100ms ease-in-out;
    position: relative;
    top: -1px;
}

.btn_apply:hover {
    background-color: #001d47;
}

.btn_page {
    color: #012965;
    line-height: 1.3;
    font-size: 0.8rem;
    padding: 0 7.5px;
    height: 26px;
    transition: background-color box-shadow 0.1s linear;
    border: 1px solid rgb(1 41 101 / 30%);
    transition: 250ms ease-in-out;
    background-color: #fff;
    outline: none !important;
}

/* .btn_page span i {
    padding-left: 10px;
    padding-right: 2px;
} */

.per_page {
    padding-right: 8px;
    font-size: 13px;
    opacity: 60%;
}

.bottomSeparator {
    margin-top: 20px;
    margin-bottom: 00px;
    width: 100% !important;
    border-bottom: solid 1px #cacaca;
}

.separator {
    margin-top: 0px;
    margin-bottom: 00px;
    width: 100% !important;
    border-bottom: solid 1px #cfcfcf;
}

.main_body {
    position: relative;
    width: 100%;
    height: calc(100% - 70px);
    padding: 15px 0 0;
    justify-content: center;
}

.table_heads {
    background-color: #ececec;
}

.table_heads th {
    font-size: 16px;
    font-weight: 400;
}

.thead_light {
    color: #001d47;
}

.tbody tr td {
    font-size: 14px;
    font-weight: 400;
}

.page_link {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #aaaaaa;
    border-radius: 40px;
    color: #001d47;
    text-decoration: none;
    height: 24px;
    width: 24px;
    margin: 8px;
    list-style: none;
}


.exportButton {
    height: 26px;
    padding: 0 15px;
    color: #012965;
    border: 1px solid rgb(1 41 101 / 30%);
    /* border-radius: 2px; */
    background-color: #ffffff;
    font-family: var(--robotoC);
    font-size: 14px;
    line-height: 14px;
}

.exportButton:hover {
    background-color: #012965;
    color: #ffffff;
}

.filterSection {
    padding: 0;
}

.filterSection .nav {
    width: 100%;
}

.typeLabel {
    border-radius: 18px;
    border: 1px solid #01296565;
    padding: 0 8px;
    background-color: #FFF;
    height: 24px;
    width: auto;
    font-size: 12px;
    font-family: var(--robotoC);
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusLabel {
    border-radius: 18px;
    padding: 0 8px;
    background-color: #FFF;
    height: 24px;
    font-size: 12px;
    width: auto;
    font-family: var(--robotoC);
    display: flex;
    align-items: center;
    justify-content: center;
}

.infoLabel {
    border-radius: 18px;
    border: 1px solid #01296565;
    padding: 0 8px;
    background-color: #FFF;
    height: 24px;
    width: auto;
    font-family: var(--robotoC);

}

.tbody>div:nth-child(even) {
    background-color: #ececec;
}

.table_header {
    height: 40px;
}

.col_sl {
    width: 6%;
}

.col_type {
    width: 15%;
}

.col_label {
    width: 15%;
}

.col_placeholder {
    width: 20%;
}

.col_image {
    width: 7.5%;
}

.col_note {
    width: 19%;
}

.col_on_off {
    width: 8%;
    display: flex;
}

.col_action {
    width: 10%;
    display: flex;
}

.table_row {
    display: flex;
    padding: 0 !important;
}

.col_on_off input {
    margin: 0 !important;
}

.table_row div {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}