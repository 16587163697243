.addSubCategoryPopup {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: #fff;
    flex-direction: column;
    box-shadow: 0px 2px 6px 5px rgb(0 0 0 / 12%), 0px 2px 4px -1px rgb(0 0 0 / 7%); */
}
.full_dynamic_formView {
    width: 100vw;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 0;
    z-index: 10;
    top: 0;
    background: #FFF;
    bottom: 0;
    padding: 0px 16px;
    
}

.itemDesIcon {
    cursor: pointer;
}

.addSub_header {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
    border-bottom: 1px solid rgb(1 41 101 / 20%);
}

.addSub_header p {
    margin: 0;
}

.addSub_header span {
    width: 16px;
    height: 16px;
}

.addSub_body {
    height: calc(100vh - 100px);
    width: 100%;
    /* padding: 40px 5px; */
    overflow-y: auto;
}

.addSub_footer {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    border-top: 1px solid rgb(1 41 101 / 20%);
}

.imageAddBtn {
    height: 38px;
    border: 1px solid #012965;
    background-color: #012965;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
}

.featureItemAddBtn {
    height: 38px;
    border: 1px solid #012965;
    background-color: #012965;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.cancelBtn {
    border: 1px solid #012965;
    background-color: transparent;
    color: #012965;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: var(--robotoC);
    height: 24px;
    width: 120px;
    margin-right: 15px;
}

.submitBtn {
    border: 1px solid #012965;
    background-color: #012965;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: var(--robotoC);
    height: 24px;
    width: 120px;
}

.img_view_container {
    width: 100%;
    height: 150px;
    border: 1px dotted #ced4da;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

.img_view_container img {
    max-height: 100%;
    max-width: 100%;
}

.add_fields {
    cursor: pointer;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #012965;
    color: #fff;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.add_fields_custom {
    cursor: pointer;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #012965;
    color: #fff;
    border-radius: 4px;
    /* width: 24px; */
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 2px;
}

.input_field_item:not(:valid) {
    border-color: rgb(255 0 0 / 20%);
}


.image_viewer {
    position: fixed;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    top:0;
    right:0
}

.image_viewer span {
    width: 50px;
    height: 50px;
    background-color: #fff;
    color: #4d4242;
    position: fixed;
    font-size: 34px;
    text-align: center;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.image_viewer img {
    width: auto;
    max-height: 100vh;
}
