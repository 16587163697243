.formContainer {
  width: 100%;
  height: 100%;
}

.formContainer.checkout {
  width: 100%;
  height: 100%;
}

.formColumn {
  width: 50%;
  padding: 0 20px;
  display: flow-root;
  float: left;
}

.checkout .formColumn {
  padding: 0 6px;
  width: 100%;
}

.inputContainer {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  min-height: 48px;
  align-items: flex-end;
}

.inputs {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  height: 42px !important;
  width: 100% !important;
  padding: 8px 20px;
  margin: 8px 0 15px;
  display: inline-block;
  color: #012965;
  letter-spacing: 0.2px;
  box-sizing: border-box;
  border-radius: 2px !important;
  border: 1px solid rgb(255 0 0 / 10%);
}

.checkout .inputs {
  margin: 0 0 15px;
}

.inputs:focus-visible {
  border: 1px solid rgb(255 0 0 / 30%) !important;
  outline: none;
}

.textAreas {
  font-family: var(--robotoC);
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  height: 108px;
  width: 100% !important;
  padding: 8px 20px;
  margin: 8px 0 15px;
  display: inline-block;
  color: #012965;
  letter-spacing: 0.2px;
  box-sizing: border-box;
  border-radius: 2px !important;
  border: 1px solid rgb(255 0 0 / 10%);
  resize: none;
}

.checkout .textAreas {
  margin: 0 0 15px;
  height: 99px;
}

.textAreas:focus-visible {
  border: 1px solid rgb(255 0 0 / 30%) !important;
  outline: none;
}

.buttonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
}

.cancelButton {
  background-color: transparent;
  color: #012965;
  height: 24px;
  margin: 8px 0 0;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  width: calc(50% - 20px);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid rgb(1 41 101 / 20%);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout .cancelButton {
  width: calc(50% - 5px);
}

.cancelButton:hover {
  background-color: #012965;
  color: #ffffff;
}

.saveButton {
  background-color: transparent;
  color: #ff0000;
  height: 24px;
  padding: 0px 20px;
  margin: 8px 0 0;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  width: calc(50% - 20px);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid rgba(255, 0, 0, 0.2);
  overflow: hidden;
}

.checkout .saveButton {
  width: calc(50% - 5px);
}

.saveButton:hover {
  background-color: #ff0000;
  color: #ffffff;
}

.inactive {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 768px) {
  .inputContainer {
    width: 100%;
    padding: 0;
  }
}